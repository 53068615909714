'use strict';
const {firebase,getCurrentEnablePersistence} = require("../src/firebase.jsx");
import { getAuth,sendEmailVerification} from "firebase/auth";
import { getStorage, ref,getMetadata } from 'firebase/storage';
import {getFirestore, increment, doc,collection, getDoc, getDocFromServer, updateDoc, deleteDoc, getDocs, setDoc,onSnapshot,writeBatch,runTransaction, Timestamp, serverTimestamp} from 'firebase/firestore';
import { displayMessage } from "../src/notification.jsx";
const EventEmitter = require('events'); 
const {nameEncode,sourceMap,getWeaponProficiency,skillsList,skillVals,languagesList,baseCoins,gamesystemOptions} = require('../lib/stdvalues.js');
const Parser = require("../lib/dutils.js").Parser;
const versionInfo = require('../data/lastversion.json');

const shardHandbookId = "079s1xff1eflg8kuaw42";
const shardMMId = "3ttfkjpia67qhug3k408";
const enforcePermissions = true;

const adventurerProductId = "1lptzx3bblfjy4bm";
const gamemasterProductId = "0mgps243ynv32dvk";
const gamemasterProProductId = "2yarsenaw01v6pj7";

const featureLookupPackage = "ndbuzvonu71wjrk4";

const defaultOwnedPackages = [
    "h6e4y2vkvno8mcvtw5kh",  // Adventure Template
    "258p2wfwtltbixbe46iq",  // AA01: Blind Fish
    "08plug6xhneypvzu08pk",  // AA02: Temple Arcana
    "a1of5deifk137vet0gcq",  // AA03: Beneath Temple Arcana
    "35czg4seolacfv8wqyqs",  // Random Encounters
    //"jgygt9y2k6kmvubns3tv",  // Playtest Materials
    //"m43clvor1uobjjzufnqe",  // Underground Oracle
    //"ddthkt9iehwnpuenukgi",  // Domille
];

const alwaysIncludePackages = [shardHandbookId,shardMMId];
const loadedPackages = {};
const checkingCharacter = {};
/*
if (false) {
    const shardHandbook = require("../data/shardhandbook.json");
    const shardMM = require("../data/shardmm.json");
    loadedPackages[shardHandbookId] =shardHandbook;
    loadedPackages[shardMMId] =shardMM;
}
*/

function getPackage(pi) {
    return loadedPackages[pi]||{};
}

// true for types that should not be browsable by players
const noPlayersBrowse ={"monsters":false, "maps":true, "mapextra":true, "encountershistory":true, "plannedencounters":true, "adventure":true, "sounds":false, "journal":false, "players":false, "pins":true, "users":false, "items":false, "mycharacters":false, "sharedcharacters":false, "books":true, "classes":false, "races":false, "customTypes":false, "feats":false, "spells":false, "backgrounds":false, "audio":true, "art":false, "randomtables":true, "shared":false, "npcs":true, "products":true, "extensions":true, "feedback":false, "chat":true};
const allNoBrowse     ={"monsters":true, "maps":true, "mapextra":true, "encountershistory":true, "plannedencounters":true, "adventure":true, "sounds":true, "journal":true, "players":true, "pins":true, "users":true, "items":true, "mycharacters":true, "sharedcharacters":false, "books":true, "classes":true, "races":true, "customTypes":true, "feats":true, "spells":true, "backgrounds":true, "audio":true, "art":true, "randomtables":true, "shared":true, "npcs":true, "products":true, "extensions":true, "feedback":true, "chat":true};

const campaignDataTypes = ["mapextra", "encountershistory", "adventure", "sounds", "journal", "players", "users", "sharedcharacters", "npcs", "feedback", "chat"];
const emptyCampaignDataTypes = ["chat"];
const userDataTypes = ["monsters", "maps", "plannedencounters", "pins", "items", "mycharacters", "books", "classes", "races", "customTypes", "feats", "spells", "backgrounds", "audio", "art", "randomtables","products","notifications","extensions"];
const sharedCampaignDataTypes=["adventure","sounds","journal", "books", "players", "sharedcharacters", "items", "classes", "races", "customTypes", "feats", "spells", "backgrounds", "users", "monsters", "audio", "art", "maps","npcs","extensions", "feedback", "chat"];

const editedDataTypes = ["monsters", "maps", "mapextra", "encountershistory", "plannedencounters", "adventure", "sounds","journal", "players", "pins", "users", "items", "mycharacters", "sharedcharacters", "books", "classes", "races", "customTypes", "feats", "spells", "backgrounds", "audio", "art", "randomtables", "shared", "npcs", "products","notifications","extensions", "feedback", "chat"];
const editedPackageDataTypes = ["monsters", "maps", "mapextra", "plannedencounters", "pins", "items", "books", "customTypes", "classes", "races", "feats", "backgrounds", "spells", "audio", "art", "randomtables", "npcs", "extensions"];
const jsonEncodeTypes={"classes":true, "feats":true, "backgrounds":true, "races":true};

// make sure to change build rountine for types in this list
const tombstoneTypes=[];

class dataListener {
    constructor(){
        this.eventSync = new EventEmitter();
        this.eventSync.setMaxListeners(500);
    }

    onGeneric(name, fn) {
        return this.eventSync.on(name, fn);
    }

    onChangeUserSettings(fn) {
        return this.eventSync.on("usersettings", fn);
    }

    onChangeCampaignSettings(fn) {
        return this.eventSync.on("campaignsettings", fn);
    }

    onChangeCampaignContent(fn, contentType) {
        return this.eventSync.on("campaigncontent."+contentType, fn);
    }

    onChangeProducts(fn) {
        return this.eventSync.on("products", fn);
    }

    on(e, fn) {
        return this.eventSync.on(e, fn);
    }

    removeGeneric(name, fn) {
        const pre = this.eventSync.listenerCount(name);
        this.eventSync.removeListener(name, fn);
        if (pre == this.eventSync.listenerCount(name)) {
            console.log("remove generic not correct", name, new Error(""));
        }
    }

    removeListener(e, fn) {
        const pre = this.eventSync.listenerCount(e);
        this.eventSync.removeListener(e, fn);
        if (pre == this.eventSync.listenerCount(e)) {
            console.log("remove not correct",e, new Error(""));
        }
    }

    removeProductsListener(fn) {
        const pre = this.eventSync.listenerCount("products");
        this.eventSync.removeListener("products", fn);
        if (pre == this.eventSync.listenerCount("products")) {
            console.log("remove products not correct", new Error(""));
        }
    }

    removeUserSettingsListener(fn) {
        const pre = this.eventSync.listenerCount("usersettings");
        this.eventSync.removeListener("usersettings", fn);
        if (pre == this.eventSync.listenerCount("usersettings")) {
            console.log("remove user settings not correct", new Error(""));
        }
    }

    removeCampaignSettingsListener(fn) {
        const pre = this.eventSync.listenerCount("campaignsettings");
        this.eventSync.removeListener("campaignsettings", fn);
        if (pre == this.eventSync.listenerCount("campaignsettings")) {
            console.log("remove campaignsettings not correct", new Error(""));
        }
    }

    removeCampaignContentListener(fn, contentType) {
        const pre = this.eventSync.listenerCount("campaigncontent."+contentType);
        this.eventSync.removeListener("campaigncontent."+contentType, fn);
        if (pre == this.eventSync.listenerCount("campaigncontent."+contentType)) {
            console.log("remove campaigncontent."+contentType+" not correct", new Error(""));
        }
    }

    postEvent(event,) {
        if (!this.postEvents) {
            this.postEvents = {};
        }
        this.postEvents[event]=true;
        if (this.postEventTimer) {
            clearTimeout(this.postEventTimer);
        }
        const t=this;
        //console.log("change", event, new Error("stack"));

        this.postEventTimer = setTimeout(function() {
            const pe = t.postEvents;
            t.postEvents=null;
            t.postEventTimer = false;
            
            for (let i in pe) {
                //console.log("emit", i, new Date());
                t.eventSync.emit(i);
            }
        }, 10);
    }

    setErrorListener(errorMessageFn, fatalErrorMessageFn) {
        this.errorMessageFn = errorMessageFn;
        this.fatalErrorMessageFn = fatalErrorMessageFn;
    }

    errorMessage(message) {
        if (this.errorMessageFn) {
            this.errorMessageFn(message);
        }
    }

    fatalErrorMessage(message) {
        if (this.fatalErrorMessageFn) {
            this.fatalErrorMessageFn(message);
        }
    }

    setStopWatch(fn) {
        this.stopWatchFn = fn;
    }

    sendStopWatch(id) {
        if (this.stopWatchFn) {
            this.stopWatchFn(id);
        }
    }

    setPageSync(pageSync) {
        this.pageSync = pageSync;
    }
}

const globalDataListener = new dataListener();
const currentDataVersion = 2;

class campaignObject {
    constructor(){
        this.unsubscribeList = [];
        this.ownedPackages = {};
        this.instanceCache = [];
        this.instance = null;
        this.ecObj = new EditedContent(null, userDataTypes, this.resetData.bind(this), {});
        this.adminStatus={};
        this.isProductionVersion = (location.hostname == "play.shardtabletop.com");
        this.isBetaVersion = (location.hostname != "play.shardtabletop.com");
        this.isDebugVersion = ["localhost", "troll.shardtabletop.com"].includes(location.hostname);
        this.requestNum=1;
        this.outstandingRequests = {};
        this.outstandingCount=0;
        this.persistenceEnabled=getCurrentEnablePersistence();
    }

    unsubscribeAll() {
        const u = this.unsubscribeList || [];

        for (let i in u) {
            u[i]();
        }
        this.unsubscribeList = [];
        
        for (let i in this.instanceCache) {
            this.instanceCache[i].unsubscribeAll();
        }
        this.instanceCache = [];
        this.ecObj.unsubscribeAll();
    }

    userEditedContent() {
        return this.ecObj.editedContent;
    }

    get userId() {
        const currentUser = getAuth(firebase).currentUser;
        return currentUser && currentUser.uid;
    }

    get displayName() {
        return getAuth(firebase).currentUser.displayName;
    }

    get email() {
        return getAuth(firebase).currentUser.email;
    }

    get currentUser() {
        return getAuth(firebase).currentUser;
    }

    get isPasswordLogon() {
        const providers = getAuth(firebase).currentUser.providerData;
        for (let i in providers) {
            if (providers[i].providerId == "password") {
                return true;
            }
        }
        return false;
    }

    async login() {
        const currentUser =this.currentUser;
        if (!currentUser) {
            throw(new Error("No logged in user"));
        }
        const t=this;
        this.instance = null;
        this.unsubscribeAll();
        this.campaigns={};
        const db = getFirestore(firebase);
        const userId = currentUser && currentUser.uid;
        this.isNew = false;

        const userDocRef = doc(db, "users",userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()){
            this.userSettings = userDoc.data();
            if ((this.userSettings.dataVersion||0) < currentDataVersion) {
                await this.upgradeStorage(this.userSettings.dataVersion||0);
            }
        } else {
            //console.log("user document did not exist");
            setDoc(userDocRef,{dataVersion:currentDataVersion}).catch(function(err){
                console.log("error updating user settings", err);
                globalDataListener.errorMessage("Error updating content: "+err.message);
            });
    
            this.isNew = true;

            // send sign_up event
            gtag('config', 'UA-159662255-1', {
                'page_title' : "sign up",
                'page_path' : "/signup",
                'user_id': userId,
            });
        }
        let promises=[];
        globalDataListener.postEvent("usersettings");

        this.unsubscribeList.push(onSnapshot(userDocRef,function(doc) {
            const d = doc.data();
            if (!areSameDeepIgnore(d, t.userSettings, ["activeTime", "activeWatchTime", "lastActiveTime", "serverTime"])) {
                globalDataListener.postEvent("usersettings");
            }
            t.userSettings = d;
        }), function (err) {if (err) console.log("user settings onsnapshot error", err)});

        const campRef = collection(db, "users",userId,"campaign");
        this.campaigns = {};
        promises.push(getDocs(campRef).then(function (campaigns) {
            campaigns.forEach(function (c) {
                const data = c.data();
                if(!data.displayName) {
                    data.displayName = data.name;
                }
                t.campaigns[data.name.toLowerCase()] = data;
            });

            t.unsubscribeList.push(onSnapshot(campRef, function (snapshot) {
                snapshot.docChanges().forEach(function (change){
                    const d = change.doc.data();
                    switch (change.type) {
                        case "modified":
                        case "added":
                            if (!d.displayName) {
                                d.displayName=d.name;
                            }
                            t.campaigns[d.name.toLowerCase()]=d;
                            break;
                        case "removed":
                            delete t.campaigns[d.name.toLowerCase()];
                            break;
                    }
                });
            }));
        }));

        promises.push(this.fetchOwnedPackages());
        promises.push(this.fetchMyPackages());
        promises.push(this.fetchStartAds());
        promises.push(this.ecObj.load());
        promises.push(this.getAdminStatus());

        this.codeVersion = versionInfo.version;
        this.serverVersion=null;
        promises.push(getDoc(doc(db, "packages",versionInfo.key)).then(function(vdoc) {
            const data = vdoc.data();
            if (data && data.version != t.serverVersion) {
                t.serverVersion = data.version
            }

            t.unsubscribeList.push(onSnapshot(doc(db, "packages",versionInfo.key), function(doc) {
                const data = doc.data();
                if (data && data.version != t.serverVersion) {
                    t.serverVersion = data.version;
                    globalDataListener.postEvent("campaignsettings");
                }
            }), function (err) {if (err) console.log("version onsnapshot error", err)});
        }));

        await Promise.all(promises);
    }

    setCampaign(newCampaignName, create, setDefault, fn) {
        const t=this;
        if (!newCampaignName) {
            newCampaignName = "emptycampaign";
        }

        if (!create && !this.campaigns[newCampaignName.toLowerCase()] && (newCampaignName != "emptycampaign")) {
            fn(new Error("Campaign '"+newCampaignName+"' not found."));
            return;
        }

        if (this.instance && (newCampaignName.toLowerCase() == this.instance.currentCampaign.toLowerCase())) {
            fn();
            return;
        }

        for (let i in this.instanceCache) {
            const inst = this.instanceCache[i];

            if (inst.currentCampaign.toLowerCase() == newCampaignName.toLowerCase()) {
                this.instance = inst;
                this.instanceCache.splice(i,1);
                this.instanceCache.push(inst);
                if (setDefault) {
                    t.updateUserSettings({currentCampaign:newCampaignName});
                }
                this.resetData();
                fn();
                return;
            }
        }

        const newInstance = new campaignInstance();

        newInstance.loadCampaignData(function (err) {
            if (!err) {
                t.instance = newInstance;
                t.instanceCache.push(newInstance);
                if (t.instanceCache.length > 3) {
                    const remove = t.instanceCache.shift();
                    remove.unsubscribeAll();
                }
                if (setDefault) {
                    t.updateUserSettings({currentCampaign:newCampaignName});
                }
            }
            t.resetData();
            fn(err);
        }, newCampaignName, create);
    }

    setWatchCampaign(id, fn) {
        const t=this;
        
        if (this.instance && (id == this.instance.watchKey)) {
            fn();
            return;
        }

        for (let i in this.instanceCache) {
            const inst = this.instanceCache[i];

            if (inst.watchKey == id) {
                this.instance = inst;
                this.instanceCache.splice(i,1);
                this.instanceCache.push(inst);
                this.resetData();
                fn();
                return;
            }
        }

        const newInstance = new campaignInstance();

        newInstance.loadWatchCampaignData(function (err) {
            if (!err) {
                t.instance = newInstance;
                t.instanceCache.push(newInstance);
                if (t.instanceCache.length > 3) {
                    const remove = t.instanceCache.shift();
                    remove.unsubscribeAll();
                }
            }
            t.resetData();
            fn(err);
        }, id);
    }

    async deleteCampaign(campaignName) {
        const db = getFirestore(firebase);
        const t=this;
        const userId = this.userId;
        const promises = [];
        const deleteTypes = editedDataTypes;

        campaignName = campaignName.toLowerCase();
        const isCur= this.instance && (this.instance.currentCampaign.toLowerCase() == campaignName);

        for (let i  in this.campaignCache) {
            if (this.campaignCache[i].currentCampaign.toLowerCase()==campaignName) {
                this.campaignCache[i].unsubscribeAll();
                this.campaignCache.splice(i,1);
            }
        }

        const campaignInfo = this.campaigns[campaignName];
        if (campaignInfo) {
            if (campaignInfo.watchKey) {
                promises.push(deleteDoc(doc(db,"watchinvites",campaignInfo.watchKey)));
            }
            if (campaignInfo.joinKey) {
                promises.push(deleteDoc(doc(db,"invites",campaignInfo.joinKey)));
            }
        }

        const mychars = this.getMyCharacters();
        for (let i in mychars) {
            const c = mychars[i];

            if (c.sharedAdventureName && (c.sharedAdventureName.toLowerCase() == campaignName)) {
                promises.push(t.joinCharacterToSharedCampaign(c.name, null));
            }
        }

        const base = doc(db,"users",userId,"campaign",nameEncode(campaignName));
        for (let i in deleteTypes) {
            const col = collection(base,deleteTypes[i]);

            const q = await getDocs(col);
            q.forEach(function (docd) {
                promises.push(deleteDoc(doc(col,docd.id)));
            });
        }
        promises.push(deleteDoc(base));
        delete this.campaigns[campaignName];
        globalDataListener.postEvent("usersettings");
        if (isCur) {
            window.location.href = "#home";
        }
        await Promise.all(promises);
    }

    upgradeStorage(ver) {
        //this.updateUserSettings({dataVersion:currentDataVersion});
    }

    incrementTime() {
        const db = getFirestore(firebase);
        const userId = this.userId;
        const cc = this.getCurrentCampaign();
        const lastActiveTime = new Date().getTime();
        const cuserDoc = doc(db, "users", userId);

        if (this.getWatchKey()) {
            //console.log("increment watch")
            updateDoc(cuserDoc,{activeWatchTime:increment(1), lastActiveTime,serverTime:serverTimestamp()}).catch(function (err){
                //console.log("error incrementing user activity", err);
            });
            return;
        }

        //console.log("incrementing activity");
        updateDoc(cuserDoc,{activeTime:increment(1), lastActiveTime,serverTime:serverTimestamp()}).catch(function (err){
            //console.log("error incrementing user activity", err);
        });

        if (cc && (cc!="emptycampaign")) {
            //console.log("incrementing campaign",cc);
            updateDoc(doc(cuserDoc, "campaign", nameEncode(cc)), {activeTime:increment(1),lastActiveTime}).catch(function (err){
                //console.log("error incrementing campaign activity", err);
            });
        } else {
            //console.log("skipping campaign",cc)
        }
    }

    get serverTimeSkew() {
        const {lastActiveTime, serverTime} = (this.userSettings||{});
        let skew=0;
        if (lastActiveTime && serverTime && serverTime.nanoseconds) {
            skew = serverTime.toMillis() - lastActiveTime;
            //console.log("skew", skew);
        }
        return skew;
    }

    async fetchMyPackages() {
        const t=this;
        const db = getFirestore(firebase);
        const userId = this.getCurrentUser().uid;
        this.myPackages = {};
        this.myLoadedPackages={};

        const mypackagesRef = collection(db,"users",userId,"mypackages" )

        const q = await getDocs(mypackagesRef)
        q.forEach(function (doc) {
            const d = doc.data();
            t.myPackages[d.id] = d;
        });
        await this.loadMyPackages()
        campaign.resetData();

        this.unsubscribeList.push(onSnapshot(mypackagesRef, function (snapshot){
            let changed;
            snapshot.docChanges().forEach(function (change){
                const d = change.doc.data();
                switch (change.type) {
                    case "modified":
                    case "added":
                        if (!areSameDeep(t.myPackages[d.id],d)) {
                            t.myPackages[d.id] = d;
                            changed=true;
                        }
                        break;
                    case "removed":
                        if (t.myPackages[d.id]){
                            delete t.myPackages[d.id];
                            changed=true;
                        }
                        break;
                }
            });
            if (changed) {
                globalDataListener.postEvent("campaigncontent.");
                globalDataListener.postEvent("campaigncontent.mypackages");

                t.loadMyPackages().then(function () {
                    globalDataListener.postEvent("campaignsettings");
                    campaign.resetData();
                }, function (err) {
                    console.log("error loading packages", err);
                });
            }
        }));
    }

    getMyPackages() {
        return this.myPackages || {};
    }

    getSortedMyPackages() {
        const pkgs = this.myPackages || {};
        const list = [];

        for (let i in pkgs) {
            list.push(pkgs[i]);
        }
        list.sort(function(a,b){return (a.name||"").toLowerCase().localeCompare((b.name||"").toLowerCase())});
        return list;
    }

    getMyPackage(id) {
        if (!id) {
            return null;
        }
        return this.getMyPackages()[id];
    }

    updateMyPackage(value) {
        const db = getFirestore(firebase);
        const userId = this.getCurrentUser().uid;

        this.myPackages[value.id]=value;

        globalDataListener.postEvent("campaigncontent.");
        globalDataListener.postEvent("campaigncontent.mypackages");

        return setDoc(doc(db,"users",userId,"mypackages",value.id), value).catch(function(err){
            globalDataListener.errorMessage("Error updating my package: "+err.message);
        });
    }

    deleteMyPackage(id) {
        const db = getFirestore(firebase);
        const userId = this.getCurrentUser().uid;
    
        delete this.myPackages[id];

        globalDataListener.postEvent("campaigncontent.");
        globalDataListener.postEvent("campaigncontent.mypackages");

        return deleteDoc(doc(db,"users",userId,"mypackages",id)).catch(function(err){
            globalDataListener.errorMessage("Error deleting package: "+err.message);
        });
    }

    sendEmailVerification() {
        const currentUser =campaign.currentUser;

        if (!currentUser.emailVerified) {
            let actionCodeSettings = {
                url: window.location.href,
                handleCodeInApp: false
            };
            return sendEmailVerification(currentUser,actionCodeSettings)
            .then(function() {
                // Verification email sent.
                console.log("verify email sent");
            })
            .catch(function(error) {
                // Error occurred. Inspect error.code.
                console.log("email send error", error);
                globalDataListener.errorMessage("Error sending verification email: "+error.message);

            });
        } else {
            return null;
        }
    }

    allowRestricted() {
        return this.ownedPackages.allowRestricted;
    }

    getPackageInfo(id) {
        return loadedPackages[id];
    }

    getFeaturePackageInfo() {
        return loadedPackages[featureLookupPackage];
    }

    async fetchOwnedPackages() {
        const t=this;
        const db = getFirestore(firebase);
        const userId = this.userId;
        const userPurchases = doc(db,"purchases",userId);

        const pdoc = await getDoc(userPurchases);
        if (pdoc.exists()) {
            this.ownedPackages = pdoc.data()||{packageList:[]};
        } else {
            this.ownedPackages = {packageList:[]};
        }
        this.ownedPurchasedPackages = computePurchasePackages(this.ownedPackages);

        await this.loadPackages();
        campaign.resetData();

        t.unsubscribeList.push(onSnapshot(userPurchases,function (doc){
            let newOP;
            if (doc.exists()) {
                newOP = doc.data()||{packageList:[]};
            } else {
                newOP = {packageList:[]};
            }
            if (!areSameDeep(newOP, t.ownedPackages)) {
                t.ownedPackages=newOP
                t.ownedPurchasedPackages = computePurchasePackages(t.ownedPackages);
                t.loadPackages().then(function () {
                    globalDataListener.postEvent("campaignsettings");
                    campaign.resetData();
                }, function (err) {
                    console.log("error loading packages", err);
                });
            }
        }));

        t.unsubscribeList.push(onSnapshot(doc(db,"notifications","packages"), function (doc){
            if (!doc.exists()) {
                return;
            }
            const {recent} = doc.data();
            for (let i in recent) {
                const {id, lastPublished} = recent[i];
                const pkg = getPackage(id);
                if (pkg && pkg.lastPublished && (pkg.lastPublished != lastPublished)) {
                    loadPackage(id,true).then(function () {
                        globalDataListener.postEvent("campaignsettings");
                        campaign.resetData();
                    }, function (err){
                        console.log("error reloading package",err);
                    });
                }
            }

        }));
    }

    async fetchStartAds() {
        const t=this;

        const db = getFirestore(firebase);
        const startAds = doc(db,"ads","start");

        const addoc = await getDoc(startAds);
        if (addoc.exists()) {
            this.startAds = addoc.data()||{};
        } else {
            this.startAds = {};
        }

        this.unsubscribeList.push(onSnapshot(startAds, function (doc){
            let newStartAds = {};
            if (doc.exists()) {
                newStartAds = doc.data()||{};
            }
            if (!areSameDeep(t.startAds, newStartAds)) {
                globalDataListener.postEvent("startads");
                t.startAds = newStartAds;
            }
        }));
    }

    updateStartAds(startAds) {
        const db = getFirestore(firebase);
        return setDoc(doc(db,"ads","start"),startAds).catch(function(err){
            console.log("error updating start ads", err);
        });
    }

    async getPublisherInfo(uid) {
        const db = getFirestore(firebase);
        let publisherInfo = {};

        const pdoc = await getDoc(doc(db,"publisher",uid));
        if (pdoc.exists()) {
            publisherInfo = pdoc.data();
        }
        return publisherInfo;
    }

    setPublisherInfo(uid, publisherInfo) {
        const db = getFirestore(firebase);

        return setDoc(doc(db,"publisher",uid),publisherInfo);
    }

    get publisher() {
        return this.ownedPackages.publisher||false;
    }

    get claimableCredits() {
        return this.ownedPackages.claimable||0;
    }

    get accountant() {
        return this.ownedPackages.accountant||false;
    }

    get owned() {
        return (this.ownedPackages||{}).products||{};
    }

    isOwned(product_id, entry_type, entry_id) {
        return isOwned(this.owned, product_id, entry_type, entry_id);
    }
        
    isPackageOwned(pkg) {
        const purchasedPackages = (this.ownedPackages||{}).purchasedPackages||{};
        const bonusPackages = (this.ownedPackages||{}).bonusPackages||{};
        const packageList = (this.ownedPackages||{}).packageList||[];
        if (purchasedPackages[pkg]) {
            return true;
        }
        if (bonusPackages[pkg]) {
            return true;
        }
        if (packageList.includes(pkg)) {
            return true;
        }
        return false;
    }

    get apiunlock() {
        return this.ownedPackages.apiunlock||false;
    }

    get dicePermitted() {
        return enforcePermissions?(this.ownedPackages.dicePermitted||"min"):"all";
    }

    get allTemplates() {
        return enforcePermissions?(this.ownedPackages.allTemplates):true;
    }

    get tokenBorders() {
        return enforcePermissions?(this.ownedPackages.tokenBorders||"min"):"all";
    }
    
    get ginnyDiDice() {
        return this.ownedPackages.ginnyDiDice||false;
    }

    get allowTemplates() {
        return enforcePermissions?(this.ownedPackages.allowTemplates||false):true;
    }
    
    get maxCharacters() {
        return enforcePermissions?(this.ownedPackages.maxCharacters||6):100;
    }
    
    get maxCampaigns() {
        return enforcePermissions?(this.ownedPackages.maxCampaigns||1):100;
    }
    
    get sharePackages() {
        return enforcePermissions?(this.ownedPackages.devAccount||this.ownedPackages.sharePackages||false):true;
    }

    get allowExport() {
        return enforcePermissions?(this.ownedPackages.allowExport||false):true;
    }

    get secondScreen() {
        return enforcePermissions?(this.ownedPackages.secondScreen||false):true;
    }

    get watchModeAllowed() {
        return enforcePermissions?(this.ownedPackages.watchModeAllowed||0):3;
    }

    get allowSpecialArtTypes() {
        return this.publisher || (this.maxCampaigns > 10);
    }
    
    get publisherSender() {
        return this.ownedPackages.publisherSender;
    }
    
    getAdminStatus() {
        const t=this;

        const db = getFirestore(firebase);
        const userId = this.userId;
        const admin = doc(db,"admins",userId);
        this.adminStatus={};

        return getDoc(admin).then(function (doc) {
            if (doc.exists()) {
                t.adminStatus = doc.data();
            }
        });
    }

    resetData(contentType,skipNotify) {
        for (let i in this.instanceCache) {
            if (this.instanceCache[i] && this.instanceCache[i].resetData) {
                this.instanceCache[i].resetData(contentType,skipNotify);
            }
        }
    }

    warnNetwork(promise) {
        if (!promise || this.persistenceEnabled) {
            return;
        }
        const t=this;
        const num = this.requestNum++;
        this.outstandingCount++;
        
        this.outstandingRequests[num]=Date.now();
        
        promise.then(function() {
            t.outstandingCount--;
            //console.log("call finished", Date.now()- t.outstandingRequests[num]);
            delete t.outstandingRequests[num];
        }, function(err) {
            t.outstandingCount--;
            delete t.outstandingRequests[num];
            //console.log("call errored", err);
        });

        if (!this.networkWatch) {
            this.networkWatch = setInterval(function(){
                if (!t.outstandingCount) {
                    clearInterval(t.networkWatch);
                    t.networkWatch=null;
                    if (t.networkBackedup) {
                        globalDataListener.postEvent("network slow update");
                        t.networkBackedup = false;
                    }
                } else{
                    let oldest=0;
                    for (let i in t.outstandingRequests) {
                        const r = t.outstandingRequests[i];
                        if (!oldest ||( r < oldest)) {
                            oldest = r;
                        }
                    }
                    const age = (Date.now() - oldest);
                    if (age > 5000) {
                        t.networkBackedup = true;
                        globalDataListener.postEvent("network slow update");
                    }
                    //console.log("age", age);

                    if ((age > 60000) && 
                        !t.showingMessage && 
                        (t.requestNum > (t.showingMessageNum||0)) && 
                        ((Date.now()-(t.lastOfflineWarning||0))> 120000)
                    ) {
                        t.showingMessage=true;
                        t.showingMessageNum = t.requestNum;
                        displayMessage("Network appears to be peforming poorly or offline. Updates are being queued. Restarting Shard may cause updates to be lost.", function() {
                            t.showingMessage=false;
                            t.lastOfflineWarning=Date.now();
                        });
                    }
                }

            },500);
        }

    }

    loadPackages(forceAll) {
        const disabledPackages = (campaign.getUserSettings().disabledPackages||{}).owned;
        const dp = forceAll?{}:disabledPackages||{};
    
        return loadPackages(this.ownedPurchasedPackages, dp);
    }

    loadPackage(pkg) {
        return loadPackage(pkg);
    }

    loadMyPackages() {
        const t=this;
        const promises = [];
        promises.push(new Promise(function(resolve, reject) {
            setTimeout(function() {
              resolve();
            }, 1);
        }));
    
        const userId = this.getCurrentUser().uid;

        const pkgs = t.myPackages;
        if (!this.myLoadedPackages) {
            this.myLoadedPackages={};
        }
        for (let i in pkgs) {
            if (!pkgs[i].majorVersion && pkgs[i].uploaded && !t.myLoadedPackages[i]) {
                promises.push(new Promise(function(resolve, reject) {
                    if (pkgs[i].packageURL) {
                        httpGetWithRetry(pkgs[i].packageURL).then(function (responseText) {
                            t.myLoadedPackages[i] = JSON.parse(responseText);
                            resolve();
                        }, function(err){
                            t.myLoadedPackages[i] = {};
                            resolve();
                            console.log("Error loading package", pkgs[i].packageURL, err.status);
                        });
                    } else {
                        const url = getDirectDownloadUrl("users/"+(pkgs[i].sharePackageUser || userId)+"/MyPackages/"+i);

                        httpGetWithRetry(url).then(function (responseText) {
                            t.myLoadedPackages[i] = JSON.parse(responseText);
                            if (pkgs[i].sharePackageUser) {
                                copyPackage(pkgs[i], t.myLoadedPackages[i]);
                            }
                            resolve();
                        }, function(err){
                            t.myLoadedPackages[i] = {};
                            resolve();
                            console.log("Error loading package", url, err.status);
                        });
                    }
                }));
            }
        }
        return Promise.all(promises);

        function copyPackage(pkg, newpkg) {
            const pkgCopyFields = ["name", "thumbnail", "userId", "publisher", "setting", "storyline", "ruleset", "startlevel", "endlevel", "description", "summary", "dependenciesDetails", "defaultBook"];
            pkgCopyFields.forEach(function(a){pkg[a]=(newpkg[a]||null)});
        }
    }

    isSharedPackage(pid) {
        const pkgs = this.ownedPackages.packageList ||[];

        const pos = pkgs.findIndex(function (p) {return (p==pid)});
        return (pos>=0);
    }

    async getAllPackages() {
        const db = getFirestore(firebase);
        const pkgs = this.ownedPurchasedPackages||[];
        const promises=[]
        const found = {};
        const list = [];
        for (let i in pkgs) {
            const p = pkgs[i].id;

            if (!found[p]) {
                promises.push(getDoc(doc(db,"packages",p)).then(function(pdoc){
                    if (pdoc.exists()) {
                        list.push(pdoc.data());
                    }
                }));
                found[p]=true;
            }
        }
        await Promise.all(promises)
        return list;
    }

    async getFullPackageList() {
        const db = getFirestore(firebase);
        const pkList = await getDocs(collection(db,"packages"));
        const list = [];
        pkList.forEach(function (pdoc) {
            const p = pdoc.data();
            // versions stored in packages collection and don't have ids
            if (p.id) {
                list.push(p);
            }
        });
        return list;
    }

    async fetchPackage(id) {
        const url = getDirectDownloadUrl("packages/"+id);
        const responseText = await httpGetWithRetry(url)

        return JSON.parse(responseText);
    }

    upsertCampaignInvite(id, data) {
        const db = getFirestore(firebase);
        const invite = doc(db,"invites",id);

        setDoc(invite,data, {merge:true}).catch(function (err){
            console.log("error updating invite", id, data, "error", err);
        });
    }

    resetCampaignInvite() {
        const db = getFirestore(firebase);
        const id = campaign.getPrefs().joinKey;
        if (id) {
            const invite = doc(db,"invites",id);

            deleteDoc(invite).then(function(){
                campaign.setPrefs({joinKey:null});
            });
        }
    }

    upsertCampaignWatchInvite(id, data) {
        const db = getFirestore(firebase);
        const invite = doc(db,"watchinvites",id);

        setDoc(invite, data, {merge:true});
    }

    async resetCampaignWatchInvite() {
        const db = getFirestore(firebase);
        const id = campaign.getPrefs().watchKey;
        if (id) {
            const invite = doc(db,"watchinvites",id);

            deleteDoc(invite).then(function(){
                campaign.setPrefs({watchKey:null});
            });
        }
    }

    async getPackageShareKey(pkgid, reset) {
        const db = getFirestore(firebase);
        const share = doc(db,"shares",pkgid);

        const sdoc = await getDoc(share);
        if (sdoc.exists() && !reset) {
            return sdoc.data().key;
        }

        const nk = {key:newUid()};
        await setDoc(share,nk);
        return nk.key;
    }

    getOwnedPackages() {
        return this.ownedPackages;
    }

    async checkCharacter(characterName) {
        if (checkingCharacter[characterName]) {
            return;
        }
        let cInfo = this.getMyCharacterInfo(characterName);
        if (!cInfo) {
            return;
        }

        if (cInfo.shareCampaign) {
            checkingCharacter[characterName]=true;
            try {
                const db = getFirestore(firebase);
                //leave previous
                const sharedchar = doc(db,"users",cInfo.shareUser,"campaign",cInfo.shareCampaign,"sharedcharacters",nameEncode(cInfo.name.toLowerCase()));

                const sdoc = await getDocFromServer(sharedchar);
                if (!sdoc.exists()) {
                    console.log("character no longer in campaign")
                    await this.joinCharacterToSharedCampaign(characterName, null);
                }
            } catch(err){
                console.log("could not determine state of character in campaign", err)
            };
            checkingCharacter[characterName]=false;
        }
    }

    async joinCharacterToSharedCampaign(characterName, campaignName) {
        const db = getFirestore(firebase);
        let cInfo = this.getMyCharacterInfo(characterName);
        let campaignInfo = this.getCampaignInfo(campaignName);

        if (!cInfo) {
            throw(new Error("No Character called "+characterName));
        }

        if (cInfo.shareCampaign) {
            //leave previous
            const sharedchar = doc(db,"users",cInfo.shareUser,"campaign",cInfo.shareCampaign,"sharedcharacters", nameEncode(cInfo.name.toLowerCase()));
    
            deleteDoc(sharedchar).catch(function (err){
                console.log("error leaving previous campaign", err.message);
            });
    
        }

        const myChar = doc(db,"users",this.userId,"mycharacters",nameEncode(characterName));

        if (!campaignName) {
            // just leave the campaign
            const myCharValue = {
                sharedAdventureName:null,
                shareCampaign:null,
                shareUser:null,
                shareCampaignName:null,
                version:getVersionId()
            };

            await setDoc(myChar, myCharValue, {merge:true});
            return;
        }

        const joinValue = {name:cInfo.name, userId:this.userId, displayName:this.displayName, email:this.email};
        const myCharValue = campaignInfo.shareCampaign?{
            sharedAdventureName:campaignName,
            shareCampaign:campaignInfo.shareCampaign,
            shareUser:campaignInfo.shareUser,
            shareCampaignName:campaignInfo.shareCampaignName,
            version:getVersionId()
        }:{
            sharedAdventureName:campaignName,
            shareCampaign:nameEncode(campaignName),
            shareUser:this.userId,
            shareCampaignName:campaignName,
            version:getVersionId()
        };

        const sharedchar = doc(db, "users",myCharValue.shareUser,"campaign",myCharValue.shareCampaign,"sharedcharacters",nameEncode(joinValue.name.toLowerCase()));
        //console.log("myCharValue", myCharValue, sharedchar);

        await setDoc(sharedchar, joinValue);
        await setDoc(myChar, myCharValue, {merge:true});
    }

    getUserSettings() {
        if (!this.userSettings) {
            this.userSettings = {};
        }
        return this.userSettings;
    }

    updateUserSettings(settings) {
        const db = getFirestore(firebase);
        const userId = this.userId;

        Object.assign(this.getUserSettings(), settings);

        const p=updateDoc(doc(db,"users",userId),settings).catch(function(err){
            console.log("error updating user settings", err, settings);
            globalDataListener.errorMessage("Error updating content: "+err.message);
        });
        campaign.warnNetwork(p);
        globalDataListener.postEvent("usersettings");
    }

    getCampaignsList() {
        let ret=[];
        for (let i in this.campaigns){
            ret.push(this.campaigns[i])
        }
        ret.sort(sortDisplayName);

        return ret;
    }

    getGMCampaignsList() {
        let ret=[];
        for (let i in this.campaigns){
            const cInfo=this.campaigns[i];
            if (!cInfo.playerMode && !cInfo.shareCampaign) {
                ret.push(this.campaigns[i])
            }
        }
        ret.sort(sortDisplayName);

        return ret;
    }

    getCampaignInfo(name) {
        if (!name) {
            return null;
        }
        return this.campaigns[name.toLowerCase()]||null;
    }

    getSourcePreventEmbedding(source) {
        const pkg = getPackage(source);
        if (!pkg) {
            return true;
        }
        return pkg.preventEmbedding&&((pkg.userId||this.userId) != this.userId)&&(!campaign.adminStatus.level);
    }

    getSourceName(source) {
        let name = sourceMap[source];

        if (name) {
            return name;
        }

        switch (source) {
            case "5ESRD":
                return "5E SRD";
        }

        return (this.myPackages[source] && this.myPackages[source].name) || (getPackage(source).name);
    }

    getSourcePublisher(source) {
        return (this.myPackages[source] && this.myPackages[source].publisher) || (getPackage(source).publisher);
    }

    getBuiltInPackages() {
        return [getPackage(shardHandbookId), getPackage(shardMMId)];
    }

    async createCampaign(cd) {
        const db = getFirestore(firebase);
        const currentUser = this.currentUser;
        const userId = currentUser.uid;

        this.campaigns[cd.name]=cd;
        await setDoc(doc(db,"users",userId,"campaign",nameEncode(cd.name)),cd);
    }
    
    async joinCampaign(key) {
        const db = getFirestore(firebase);
        const currentUser = this.currentUser;
        const userId = currentUser.uid;

        const invite = await getDocFromServer(doc(db,"invites",key));
        if (!invite.exists()) {
            throw(new Error("Invalid invite"));
        }
        const inviteData = invite.data();
        const shareCampaign = nameEncode(inviteData.campaign);
        const shareUser = inviteData.user;

        const u = {name:userId, displayName:currentUser.displayName, email:currentUser.email, key:key, user:userId, version:getVersionId()};

        await setDoc(doc(db,"users",shareUser,"campaign",shareCampaign,"users",userId),u);
        let campaignCur = this.findSharedCampaign(shareCampaign, shareUser);
        if (!campaignCur) {
            const cdoc = await getDocFromServer(doc(db,"users",shareUser,"campaign",shareCampaign))
            const shareCampaignName = cdoc.data().name;
            campaignCur = newUid();
            const cd = {
                name:campaignCur,
                displayName:cdoc.data().displayName||shareCampaignName,
                shareUser:shareUser,
                shareCampaign:shareCampaign,
                shareCampaignName:shareCampaignName,
                deactivatedPackages:{},
                playerMode:true
            }

            await setDoc(doc(db,"users",userId,"campaign",nameEncode(campaignCur)),cd);
            this.campaigns[cd.name]=cd;
        }
        return campaignCur;
    }
    
    findSharedCampaign(campaignName, user) {
        for (let i in this.campaigns) {
            const c = this.campaigns[i];

            if ((c.shareUser == user) && (c.shareCampaign == campaignName)) {
                return i;
            }
        }
        return null;
    }

    checkCampaigns() {
        const t = this;
        const db = getFirestore(firebase);

        for (let i in this.campaigns) {
            const c = this.campaigns[i];

            if (c.shareUser) {
                try {
                    getDocFromServer(doc(db,"users",c.shareUser,"campaign",nameEncode(c.shareCampaignName))).then(function(doc) {
                        if (!doc.exists()) {
                            console.log("campaign not found", i, c);
                            t.deleteCampaign(i);
                        }
                    });
                } catch (err) {
                    
                }
            }
        }
    }
    
    getSharedAdventures() {
        let ret=[];

        for (let i in this.campaigns){
            const c = this.campaigns[i];
            if (c.shareCampaign) {
                ret.push(c);
            }
        }

        ret.sort(sortDisplayName);
        return ret;
    }

    getSharedAdventuresInfo(name) {
        if (!name)
            return null;
        const camp = this.campaigns[name.toLowerCase()];
        if (!camp.shareCampaign) {
            return null;
        }
        return camp;
    }

    async publishPackage(pkgInfo, rawpkg, oldPkg) {
        const t=this;
        const pkg = Object.assign({}, rawpkg);
        const ips = [];
        pkgInfo.lastPublished = (new Date()).getTime();
        if (pkgInfo.packageURL) {
            delete pkgInfo.packageURL;
        }
        Object.assign(pkg, pkgInfo);

        const images = {};
        const imageNames = {};

        for (let p in pkg) {
            let e = pkg[p];
            if (Array.isArray(e)) {
                e = pkg[p] = e.concat([]);
                for (let i in e) {
                    e[i]= Object.assign({}, e[i]);
                    if (e[i].source != pkg.id) {
                        e[i].source = pkg.id;
                        delete e[i].page;
                    }
                    delete e[i].edited;
                    delete e[i].version;
                }
            }
        }

        // find images in pkg
        {
            const list = pkg.art;
            for (let x in list) {
                const l = list[x];

                addImage(l.url, l.artVersionId||l.name, "u", l.displayName);
                addImage(l.thumb, l.artVersionId||l.name, "t", l.displayName);
            }
        }
        {
            const list = pkg.audio;
            for (let x in list) {
                const l = list[x];

                addImage(l.url, l.versionId||l.name, "m", l.displayName);
            }
        }

        //console.log("images", images);
        for (let i in images) {
            const oldName = i;
            const relPath = images[i];
            const newpath = getDirectDownloadUrl("packages/"+pkg.id+".i/"+relPath);

            if ((newpath == oldName)||(oldName.includes("/packages%2F") && oldName.includes(relPath))) {
                // art not changed
                images[oldName] = newpath;
            } else {
                try {
                    await httpAuthRequestWithRetry("POST", "/search?cmd=publishblob", JSON.stringify({id:pkg.id, oldName, relPath}));
                    images[oldName] = newpath;
                } catch (err) {
                    console.log("error publishing blob", oldName)
                    throw (err);
                }
            }
        }

        try {
            const list = pkg.art;
            for (let x in list) {
                const l = list[x];

                if (l.url) {l.url = images[l.url];}
                if (l.thumb) {l.thumb = images[l.thumb];}
            }

            const listau = pkg.audio;
            for (let x in listau) {
                const l = listau[x];

                if (l.url) {l.url = images[l.url];}
            }

            const list2 = pkg.mycharacters;
            for (let x in list2) {
                const l = list2[x];

                if (l.imageURL && images[l.imageURL]) {l.imageURL = images[l.imageURL];}
            }
            
            await httpAuthRequestWithRetry("POST", "/search?cmd=publishpackage", JSON.stringify({pkg, pkgInfo, backup:!!oldPkg}));
            loadedPackages[pkg.id] = pkg;

            await t.updateMyPackage(pkgInfo);
            const delList = [];
            // delete edited content
            for (let p in pkg) {
                let e = pkg[p];
                if (Array.isArray(e)) {
                    for (let i in e) {
                        delList.push({delete:true, name:e[i].name, contentType:p});
                    }
                }
            }
            t.batchUpdateCampaignContent(delList);

            campaign.resetData();
        } catch (err) {
            console.log("Error uploading package", pkg.name, err);
            throw (err);
        }

        function addImage(image, name, type, displayName) {
            if (!image) {
                return;
            }

            const path = nameEncode(name)+"."+type;

            images[image]=path;
            imageNames[path]=displayName;
        }        
    }

    isPlayerMode() {
        return !!this.getPrefs().playerMode;
    }

    isCampaignGame() {
        return !this.isDefaultCampaign() && (this.getPrefs().playerMode!= "ruleset");
    }

    isSharedCampaign() {
        const p = this.getPrefs();
        return !!(p.playerMode && p.shareUser);
    }

    newUid() {
        return newUid();
    }

    random(n) {
        if (window.crypto) {
            let array = new Uint32Array(1);
            window.crypto.getRandomValues(array);
            return (array[0] % n);
        }
        return Math.trunc(Math.random()*n);
    }

    getMRUList(mrulist, ignore) {
        return this.instance.getMRUList(mrulist, ignore);
    }

    addMRUList(mrulist, a) {
        return this.instance.addMRUList(mrulist, a);
    }

    getUserMRUList(mrulist, ignore) {
        let mru = this.getUserSettings()[mrulist]||[];

        if (ignore) {
            const fi = mru.findIndex(function (m) {return m.description==ignore});
            if (fi >= 0) {
                mru = mru.concat([]);
                mru.splice(fi, 1);
            }
        }
        return mru;
    }

    addUserMRUList(mrulist, a) {
        let mru = (this.getUserSettings()[mrulist]||[]).concat([]);
        let ns ={};

        const fi = mru.findIndex(function (m) {return m.description==a.description});
        if (fi==0) {
            // already at top of mru
            return;
        }
        if (fi > 0) {
            mru.splice(fi, 1);
        }
        mru.unshift(a);
        if (mru.length > 10) {
            mru = mru.slice(0,9);
        }
        ns[mrulist] = mru;
        this.updateUserSettings(ns);
    }

    removeUserMRUList(mrulist, i) {
        let mru = (this.getUserSettings()[mrulist]||[]).concat([]);
        let ns ={};

        mru.splice(i,1);
        ns[mrulist] = mru;
        this.updateUserSettings(ns);
    }

    getCurrentCampaign() {
        if (this.instance) {
            return this.instance.getCurrentCampaign();
        }
        return "";
    }

    getWatchKey() {
        if (this.instance) {
            return this.instance.getWatchKey();
        }
        return null;
    }

    isDefaultCampaign() {
        return this.getCurrentCampaign()=="emptycampaign";
    }

    isGMCampaign() {
        return !this.isPlayerMode() && !this.isDefaultCampaign();
    }

    getPrefs() {
        if (this.instance) {
            return this.instance.getPrefs();
        }
        return {};
    }

    setPrefs(prefs) {
        return this.instance.setPrefs(prefs);
    }

    getCurrentPackageList() {
        if (!this.instance){
            return [];
        }
        return this.instance.getCurrentPackageList();
    }

    getSharing() {
        return this.instance.getSharing();
    }

    isSharedEdited(name) {
        return this.instance.isSharedEdited(name);
    }

    isCampaignPackage(pid) {
        return this.instance.isCampaignPackage(pid);
    }

    getAvailablePackageList() {
        return this.instance.getAvailablePackageList();
    }

    alwaysIncludePackages() {
        return alwaysIncludePackages;
    }

    defaultOwnedPackages() {
        return defaultOwnedPackages;
    }

    getEditedPackage() {
        return this.instance.getEditedPackage()
    }

    setGlobalDisabledPackages(disabledPackages) {
        this.updateUserSettings({disabledPackages});
        this.loadPackages();
        campaign.resetData();
    }

    getSharedCharacterInfo(name){
        return this.instance.getSharedCharacterInfo(name);
    }

    getAllSharedCharacters() {
        return this.instance.getAllSharedCharacters();
    }

    updateCampaignContent(contentType, value) {
        if (userDataTypes.includes(contentType)) {
            return this.ecObj.updateCampaignContent(contentType, value);
        }
        return this.instance.updateCampaignContent(contentType, value);
    }

    batchUpdateCampaignContent(list) {
        return this.ecObj.batchUpdateCampaignContent(list);
    }

    versionUpdateCampaignContent(contentType, value) {
        if (userDataTypes.includes(contentType)) {
            return this.ecObj.versionUpdateCampaignContent(contentType, value);
        }
        return this.instance.versionUpdateCampaignContent(contentType, value);
    }

    deleteCampaignContent(contentType, name, noTombstone){
        if (userDataTypes.includes(contentType)) {
            return this.ecObj.deleteCampaignContent(contentType, name, noTombstone);
        }
        return this.instance.deleteCampaignContent(contentType, name, noTombstone);
    }

    updateAdventureView(value){
        return this.instance.updateAdventureView(value);
    }

    getAdventureView() {
        return this.instance.getAdventureView();
    }

    getCampaignDice() {
        return this.instance.getCampaignDice();
    }

    getGameState() {
        return this.instance.getGameState();
    }

    updateGameState(state) {
        return this.instance.updateGameState(state);
    }

    getWallpaper() {
        return this.instance.getWallpaper();
    }

    get gamesystems() {
        return this.getGameState().gamesystems;
    }

    get defaultGamesystem() {
        const g = this.getGameState().defaultGamesystem;
        if (g) {
            return g;
        }
        const gs = this.gamesystems;
        if (gs && gs.includes("bf")) {
            return "bf";
        }
        if (gs && gs.includes("5e24")) {
            return "5e24";
        }
        return "5e";
    }

    get hasBFGamesystem() {
        const gs = this.gamesystems;
        if (gs && gs.includes("bf")) {
            return true;
        }
    }

    get racesText() {
        const gso = this.filteredGamesystemOptions;
        if (gso["5e24"]) {
            if (gso["5e"]) {
                return "Races/Species";
            }
            return "Species";
        }
        return "Races";
    }

    get raceText() {
        const gso = this.filteredGamesystemOptions;
        if (gso["5e24"]) {
            if (gso["5e"]) {
                return "Race/Species";
            }
            return "Species";
        }
        return "Race";
    }

    get filteredGamesystemOptions() {
        const gamesystems = this.gamesystems;
        const options = Object.assign({}, gamesystemOptions);
        if (gamesystems) {
            for (let gs in gamesystemOptions) {
                if (!gamesystems.includes(gs)) {
                    delete options[gs];
                }
            }
        } else {
            Object.assign(options, gamesystemOptions);
            // check races for 5E options
            const races = campaign.getAllRaces();
            let has5e,has5e24;
            for (let i in races) {
                const r = races[i];
                switch (r.gamesystem) {
                    case "5e24":
                        has5e24 = true;
                    default:
                    case "5e":
                        has5e=true;
                        break;
                }
            }
            // check for lineages for bf
            const lineages = campaign.getSortedCustomList("Lineages");

            if (!has5e) {
                delete options["5e"];
            }
            if (!lineages.length) {
                delete options["bf"];
            }
            if (!has5e24) {
                delete options["5e24"];
            }

        }
        return options;
    }

    copyGameSettings(campSource, campTarget) {
        const db = getFirestore(firebase);

        const userId =campaign.currentUser?.uid;
        const sourceDocRef = doc(db,"users",userId,"campaign",nameEncode(campSource),"adventure","settings");
        const targetDocRef = doc(db,"users",userId,"campaign",nameEncode(campTarget),"adventure","settings");

        getDoc(sourceDocRef).then(function (sdoc) {
            if (sdoc.exists()) {
                setDoc(targetDocRef, sdoc.data());
            }
        });
    }

    getPingList() {
        return this.instance.getPingList();
    }

    getUserList() {
        return this.instance.getUserList();
    }

    getUserInfo(name) {
        return this.instance.getUserInfo(name);
    }

    getCurrentUser() {
        return this.currentUser;
    }

    getBookList(){
        return this.instance.getBookList();
    }

    getAllBooks() {
        return this.instance.getAllBooks();
    }

    getVisibleBooks() {
        return this.instance.getVisibleBooks();
    }

    getBookInfo(book){
        return this.instance.getBookInfo(book);
    }

    getBookFragment(name) {
        return this.instance.getBookFragment(name);
    }

    getMaps() {
        return this.instance.getMaps();
    }

    getMapInfo(mapname) {
        return this.instance.getMapInfo(mapname);
    }

    getMapExtraInfo(mapname) {
        return this.instance.getMapExtraInfo(mapname);
    }

    getArt() {
        return this.instance.getArt();
    }

    getArtInfo(objname) {
        return this.instance.getArtInfo(objname);
    }

    getAudio() {
        return this.instance.getAudio();
    }

    getAudioInfo(objname) {
        return this.instance.getAudioInfo(objname);
    }

    getRandomTables() {
        return this.instance.getRandomTables();
    }

    getRandomTableInfo(objname) {
        return this.instance.getRandomTableInfo(objname);
    }

    getSortedPinsList() {
        return this.instance.getSortedPinsList();
    }

    getPins() {
        return this.instance.getPins();
    }

    getPinInfo(pinname) {
        return this.instance.getPinInfo(pinname);
    }

    findEncounterPin(encounterName) {
        return this.instance.findEncounterPin(encounterName);
    }

    getAdventure() {
        return this.instance.getAdventure();
    }

    updateAdventure(update) {
        return this.instance.updateAdventure(update);
    }

    getSharedTreasure() {
        return this.instance.getSharedTreasure();
    }

    getHandouts() {
        if (!this.instance) {
            return {};
        }
        return this.instance.getHandouts();
    }

    getEncountersHistory() {
        return this.instance.getEncountersHistory();
    }

    getEncounterHistoryInfo(name) {
        return this.instance.getEncounterHistoryInfo(name);
    }

    getChat() {
        return this.instance.getChat();
    }

    getChatInfo(name) {
        return this.instance.getChatInfo(name);
    }

    getJournal() {
        return this.instance.getJournal();
    }

    getJournalInfo(name) {
        return this.instance.getJournalInfo(name);
    }

    getSounds() {
        return this.instance.getSounds();
    }

    getSoundsInfo(name) {
        return this.instance.getSoundsInfo(name);
    }

    getPlannedEncounters() {
        return this.instance.getPlannedEncounters();
    }

    getPlannedEncounterInfo(name) {
        return this.instance.getPlannedEncounterInfo(name);
    }

    getNotifications() {
        const editedContent = this.userEditedContent();
        const notifications = [];

        for (let i in editedContent.notifications){
            const p = editedContent.notifications[i];
            notifications.push(p);
        }
        notifications.sort(function(a,b){return (a.addtime||0)-(b.addtime||0)});

        return notifications;
    }

    getAllProducts() {
        const editedContent = this.userEditedContent();
        const allProducts = {};

        for (let i in editedContent.products){
            const p = editedContent.products[i];
            allProducts[p.name]=p;
        }

        return allProducts;
    }

    getProducts() {
        const allProducts = this.getAllProducts();
        const list=[];

        for (let i in allProducts){
            list.push(allProducts[i]);
        }

        list.sort(sortDisplayName);
        return list;
    }

    getProductInfo(name) {
        if (!name)
            return null;
        return this.getAllProducts()[name.toLowerCase()];
    }

    getPlayers() {
        return this.instance.getPlayers();
    }

    getPlayerInfo(name) {
        return this.instance.getPlayerInfo(name);
    }

    getFeedback() {
        return this.instance.getFeedback();
    }

    getFeedbackInfo(name) {
        return this.instance.getFeedbackInfo(name);
    }

    getMyCharacters() {
        if (!this.savedMyCharactersList) {
            let ret=[];
            const allC = this.getAllCharacters();

            for (let i in allC){
                const p = allC[i];
                if (!p.pregen) {
                    ret.push(p);
                }
            }

            ret.sort(sortDisplayName);
            this.savedMyCharactersList= ret;
        }
        return this.savedMyCharactersList;
    }

    getPregens() {
        if (!this.savedPregenList) {
            let ret=[];
            const allC = this.getAllCharacters();

            for (let i in allC){
                const p = allC[i];
                if (p.pregen) {
                    ret.push(p);
                }
            }

            ret.sort(sortDisplayName);
            this.savedPregenList= ret;
        }
        return this.savedPregenList;
    }

    getMyCharacterInfo(name) {
        if (!name)
            return null;
        name = name.toLowerCase();
        const all =this.getAllCharacters();
        return this.noBrowseAllCharacters[name] || all[name] || this.demoteAllCharacters[name];
    }

    getAllCharacters() {
        if (!this.allCharacters) {
            const allCharacters = {};
            const noBrowseAllCharacters = {};
            const demoteAllCharacters = {};
            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.mycharacters;

                if (p.mycharacters) {
                    for (let c in p.mycharacters) {
                        const pre = p.mycharacters[c];
                        addEntryToSets(allCharacters, noBrowseAllCharacters, demoteAllCharacters, canbrowse, pre, null, "Pregenerated Characters", pinfo);
                    }
                }
            }

            const editedContent = this.userEditedContent();

            for (let i in editedContent.mycharacters){
                const p = editedContent.mycharacters[i];
                if (!p.displayName) {
                    p.displayName = p.name;
                }
                allCharacters[p.name.toLowerCase()]=p;
            }

            this.allCharacters= allCharacters;
            this.noBrowseAllCharacters = noBrowseAllCharacters;
            this.demoteAllCharacters = demoteAllCharacters;
        }
        return this.allCharacters;
    }

    getAllMonsters() {
        return this.instance.getAllMonsters();
    }

    getMonsterListByName() {
        return this.instance.getMonsterListByName();
    }
    
    getMonster(name) {
        return this.instance.getMonster(name);
    }

    getMonsterInfo(name) {
        return this.instance.getMonster(name);
    }

    getNPCs() {
        return this.instance.getNPCs();
    }

    getRaces(){
        return this.instance.getRaces();
    }

    getAllRaces(){
        return this.instance.getAllRaces();
    }

    getRaceInfo(race){
        return this.instance.getRaceInfo(race);
    }

    getAllFeats() {
        return this.instance.getAllFeats();
    }

    getSortedFeatsList() {
        return this.instance.getSortedFeatsList();
    }

    getFeatInfo(name) {
        return this.instance.getFeatInfo(name);
    }

    getAllExtensions() {
        return this.instance.getAllExtensions();
    }

    getSortedExtensionsList() {
        return this.instance.getSortedExtensionsList();
    }

    getExtensionInfo(name) {
        return this.instance.getExtensionInfo(name);
    }

    getAllSkills() {
        return this.instance.getAllSkills();
    }

    getAllSkillsWithAbilities() {
        return this.instance.getAllSkillsWithAbilities();
    }

    getExtensionSkillDescriptions(){
        return this.instance.getExtensionSkillDescriptions();
    }

    getAllLanguages() {
        return this.instance.getAllLanguages();
    }

    getAllBackgrounds() {
        return this.instance.getAllBackgrounds();
    }

    getSortedBackgroundsList() {
        return this.instance.getSortedBackgroundsList();
    }

    getBackgroundInfo(name) {
        return this.instance.getBackgroundInfo(name);
    }

    getAllItems() {
        return this.instance.getAllItems();
    }

    getItemExtensions() {
        return this.instance.getItemExtensions();
    }

    getSortedItemsList() {
        return this.instance.getSortedItemsList();
    }

    getItem(name) {
        return this.instance.getItem(name);
    }

    getAllCustom(type) {
        return this.instance.getAllCustom(type);
    }

    getAllCustomList() {
        return this.instance.getAllCustomList();
    }

    getSortedCustomList(type) {
        return this.instance.getSortedCustomList(type);
    }

    getCustom(type, name) {
        return this.instance.getCustom(type, name);
    }

    getCustomTablesList(includeExtra) {
        return this.instance.getCustomTablesList(includeExtra);
    }

    getAllSpells() {
        return this.instance.getAllSpells();
    }

    getSpellListByName() {
        return this.instance.getSpellListByName();
    }
    
    getSpell(name) {
        return this.instance.getSpell(name);
    }

    getClassesListByName() {
        return this.instance.getClassesListByName();
    }
    
    getSubclasses(cls) {
        return this.instance.getSubclasses(cls);
    }
    
    getClasses() {
        return this.instance.getClasses();
    }

    getClassInfo(cls) {
        return this.instance.getClassInfo(cls);
    }

    getSubclassInfo(subclass) {
        return this.instance.getSubclassInfo(subclass);
    }

    getSubclassTree() {
        return this.instance.getSubclassTree();
    }

    async addShareCampaign(campaign, user) {
        const shareCampaigns = this.getShareCampaigns();
        await this.updateShareCampaigns(campaign, user, shareCampaigns);
    }

    async removeShareCampaign(campaign) {
        const shareCampaigns = this.getShareCampaigns();
        const pos = shareCampaigns.indexOf(campaign);

        if (pos >=0) {
            shareCampaigns.splice(pos,1);
            await this.updateShareCampaigns(null, null, shareCampaigns);
        }
    }

    getShareCampaigns() {
        const shareCampaigns = (this.ownedPackages.shareCampaigns||[]).concat([]);
        for (let i=shareCampaigns.length-1; i>=0; i--) {
            let found;
            for (let x in this.campaigns) {
                if (this.campaigns[x].shareCampaignName == shareCampaigns[i]) {
                    found=true;
                    break;
                }
            }
            if (!found) {
                //console.log("found orphaned campaign", shareCampaigns[i]);
                shareCampaigns.splice(i,1);
            }
        }
        return shareCampaigns;
    }

    async addNewImports(newImports) {
        //console.log("addNewImports", newImports);
        await httpAuthRequestWithRetry("POST", "/search?cmd=addimports", 
            JSON.stringify(newImports)
        ).then(function(){
            //console.log("successfuly added new imports")
        }, function (err) {
            console.log("error adding new imports", err)
        });
        await this.fetchOwnedPackages();
        // give time for async time to process notifications
        await sleep(150);
    }

    async updateShareCampaigns(addCampaign, campaignUser, shareCampaigns) {
        //console.log("update Share campaigns", addCampaign, campaignUser, shareCampaigns);
        await httpAuthRequestWithRetry("POST", "/search?cmd=updatesharelibrary", 
            JSON.stringify({addCampaign, campaignUser, shareCampaigns}
        )).then(function(){
            //console.log("successfuly updated share campaigns")
        }, function (err) {
            console.log("error updateShareCampaigns", err)
        });
    }

    registerNewUser(ref, isNew) {
        //console.log("sending register new user")
        httpAuthRequestWithRetry("POST", "/search?cmd=registernewuser", JSON.stringify({ref,email:this.email, displayName:this.displayName, isNew})).then(function(){
            //console.log("registered new user", ref)
        }, function (err) {
            console.log("error registering new user", ref, err)
        });
    }

    async updateCouponInCart(code) {
        if (code) {
            const currentUser = this.getCurrentUser();
            if (!currentUser) {
                return null;
            }
            const userId= currentUser.uid;
            const db = getFirestore(firebase);

            const cart = await getDoc(doc(db,"cart",userId));
            const newCart = cart.exists()?cart.data():{};
    
            if (!newCart.coupons || !newCart.coupons.includes(code)) {
                if (!newCart.coupons) {
                    newCart.coupons = [code];
                } else {
                    newCart.coupons.push(code);
                }
                await setDoc(doc(db,"cart",userId),newCart);
            }
        }
    }

    isShardSubscription(productId) {
        return [adventurerProductId,gamemasterProductId,gamemasterProProductId].includes(productId);
    }

    hasHigherShardSubscription(productId) {
        switch (productId) {
            case adventurerProductId:
                return this.isOwned(gamemasterProductId) || this.isOwned(gamemasterProProductId);
            case gamemasterProductId:
                return this.isOwned(gamemasterProProductId);
            case gamemasterProProductId:
                break;
            default:
                break;
        }
        return false;
    }

    get showDebugDice() {
        let r=this.getUserSettings().showDebugDice;
        if (r==null) {
            r=false;
        }
        return r;
    }

    get playSounds() {
        let r=this.getUserSettings().playSounds;
        if (r==null) {
            r=true;
        }
        return r;
    }

    get diceVolume() {
        let r=this.getUserSettings().diceVolume;
        if (r==null) {
            r=50;
        }
        return r;
    }


    get volumeSettings() {
        let {backgroundVolume, effectVolume, voiceVolume }= this.getUserSettings();
        let diceVolume=this.playSounds?this.diceVolume:0;
        if (effectVolume==null) {
            if (diceVolume!=null) {
                effectVolume=diceVolume;
            } else {
                effectVolume=50;
            }
        }
        if (backgroundVolume==null) {
            backgroundVolume=50;
        }
        if (voiceVolume==null) {
            voiceVolume=100;
        }
        return {backgroundVolume, effectVolume, voiceVolume, diceVolume};
    }
}

class EditedContent {
    constructor(campaignName, types, resetDataCallback, prefs) {
        this.types = types;
        this.campaignName = campaignName;
        this.editedContent={};
        this.resetDataCallback = resetDataCallback;
        this.prefs = prefs;
        this.recentChanges=[];
        this.transactionQueue=[];
    }

    resetData(contentType,skipNotify) {
        if (!contentType || contentType=="sharedcharacters") {
            this.onChangeSharedCharacters();
        }
        this.resetDataCallback(contentType, skipNotify);
    }

    unsubscribeAll() {
        const u = this.unsubscribeList || [];

        for (let i in u) {
            u[i]();
        }
        this.unsubscribeList = [];
        if (this.unsubscribeShared) {
            this.unsubscribeShared();
            this.unsubscribeShared = null;
        }
        this.unsubscribeSharedCharacters();
        this.editedContent={};
        this.loadedContent={};
    }

    unsubscribeSharedCharacters() {
        const u = this.unsubscribeSharedCharactersList || [];

        for (let i in u) {
            u[i]();
        }
        this.unsubscribeSharedCharactersList = [];
    }

    getEditedContentCollection(contentType, value) {
        const db = getFirestore(firebase);
        let userId = campaign.userId;
        let campaignName = this.campaignName;

        if ((contentType=="players") && value) {
            const sc = this.editedContent.sharedcharacters[value.name.toLowerCase()];

            if (sc) {
                return collection(db,"users",sc.userId,"mycharacters");
            }
        }

        if (this.prefs.playerMode && this.prefs.shareUser && sharedCampaignDataTypes.includes(contentType)){
            userId=this.prefs.shareUser;
            campaignName=this.prefs.shareCampaignName;
        }

        if (!campaignName || userDataTypes.includes(contentType)) {
            return collection(db,"users",userId,contentType);
        }

        return collection(db,"users",userId,"campaign",nameEncode(campaignName),contentType);
    }

    async load() {
        const t=this;
        this.unsubscribeAll();
        let promises=[];

        for (let i in this.types){
            const dt = this.types[i];
            const collectionBase = this.getEditedContentCollection(dt);
            let p;

            if (!this.editedContent[dt]) {
                this.editedContent[dt]={};
            }

            p =getDocs(collectionBase).then(function (q) {
                q.forEach(function (doc) {
                    let d = doc.data();

                    if (d.jsonData) {
                        d = JSON.parse(d.jsonData);
                    }
                    d.edited=true;
                    if (d.name) {
                        const dname = d.name.toLowerCase();
                        t.editedContent[dt][dname]=d;
                    } else {
                        console.log("why no name", d);
                    }
                });

                t.unsubscribeList.push(onSnapshot(collectionBase, function (snapshot){
                    let madeChanges = false;
                    let resetAll = false;
                    let foundBookTOC = false;
    
                    snapshot.docChanges().forEach(function (change){
                        let d = change.doc.data();
                        if (d.jsonData) {
                            d = JSON.parse(d.jsonData);
                        }
                        switch (change.type) {
                            case "added":
                                foundBookTOC=true;
                            case "modified":
                                if (d.name) {
                                    const dname = d.name.toLowerCase();
                                    if (dname == "settings") {
                                        // changing settings could change package list so reset all
                                        resetAll=true;
                                    }
                                    if (dt=="books" && d.type=="book") {
                                        foundBookTOC=true;
                                    }
                                    if (!t.isTransactionUpdate(dt,dname)) {
                                        d.edited=true;
                                        if (!t.editedContent[dt][dname] || !areSameDeep(t.editedContent[dt][dname],d)) {
                                            const diff = ((t.editedContent[dt][dname]||{}).timestamp||0)- (d.timestamp||0);
                                            if ((diff > 0) && change.doc.metadata.hasPendingWrites) {
                                                //console.log("skipping update since it recently goes backwards", change.doc.metadata);
                                            } else {
                                                if (diff>0) {
                                                    //console.log("backwards but allowed", change.doc.metadata)
                                                }
                                                //console.log("changed",dt, "from",t.editedContent[dt][dname],"to",d);
                                                t.editedContent[dt][dname] = d;
                                                madeChanges = true;
                                            }
                                        }
                                    } else {
                                        //console.log("skipping update due to trans in progress");
                                    }
                                    //console.log("added/changed", change.doc.data().name, "to", dt);
                                }
                                break;
                            case "removed":
                                foundBookTOC=true;
                                const dname = d.name.toLowerCase();
                                if (t.editedContent[dt] && t.editedContent[dt][dname]) {
                                    delete t.editedContent[dt][dname];
                                    //console.log("removed", d.name);
                                    madeChanges = true;
                                }
                                break;
                        }
                    });
                    if (madeChanges) {
                        if (resetAll) {
                            t.resetData();
                        } else {
                            if (dt == "mycharacters") {
                                t.resetData("players");
                            }
                            if (foundBookTOC && (dt=="books")) {
                                globalDataListener.postEvent("campaigncontent.booktoc");
                            }
                            t.resetData(dt);
                        }
                    }
                }, function (err) {if (err) console.log("edited campaign content onsnapshot error", err)}));
            });

            if (dt == "sharedcharacters") {
                p = this.getSharedCharacters(p);
            }

            promises.push(p);
            if (promises.length > 2) {
                await Promise.all(promises);
                promises=[];
            }
        }

        await Promise.all(promises);
    }

    updateCampaignContent(contentType, value) {
        const t=this;

        //onsole.log("update campaign content", contentType, new Error("stack"))
        if (!this.types.includes(contentType)) {
            console.log("bad content update not in type", contentType, value);
            this.resetData();
            return;
        }
        value = Object.assign({},value); // make sure that we don't modify in place

        value.edited = true;
        delete value.version;
        value.timestamp = Date.now();
        let ret = null;
        let data = this.editedContent[contentType];
        if (!data) {
            data = this.editedContent[contentType]={};
        }
        // make sure that time doesn't go backwards
        const old = data[value.name.toLowerCase()];
        if (old?.timestamp && old.timestamp>value.timestamp) {
            value.timestamp = old.timestamp+1;
        }

        data[value.name.toLowerCase()] = value;

        try {
            const setValue = jsonEncodeTypes[contentType]?{name:value.name, jsonData:JSON.stringify(value)}:value

            const name = (contentType=="users")?value.name:nameEncode(value.name.toLowerCase());
            ret = setDoc(doc(this.getEditedContentCollection(contentType, value),name),setValue);
            campaign.warnNetwork(ret);
            ret.then(function(){
                //console.log("campaign",t.campaignName,"content updated for", contentType, "and item", value.name);
            },function (err){
                console.log("error updating campaign content updated for", t.campaignName, contentType, "and item", value.name, "error", err);
                globalDataListener.errorMessage("Error updating content: "+err.message);
            });
        } catch (err) {
            globalDataListener.errorMessage("Error updating content: "+err.message);
            console.log("update campaign content", err, contentType, value);
        }
        this.resetData(contentType);
        return ret;
    }

    batchUpdateCampaignContent(list) {
        const t=this;
        const db = getFirestore(firebase);
        let batch = writeBatch(db);
        let count = 0;
        const promises = [];

        for (let i in list) {
            const le = list[i];
            const contentType=le.contentType;

            if (!contentType) {
                continue;
            }
            
            if (!this.types.includes(contentType)) {
                console.log("bad batch content update not in type", contentType);
                this.resetData();
                return;
            }
            let data = this.editedContent[contentType];
            if (!data) {
                data = this.editedContent[contentType]={};
            }
    
            if (le.delete) {
                const ref = doc(this.getEditedContentCollection(contentType),nameEncode(le.name.toLowerCase()));
                batch.delete(ref);
                delete data[le.name];
            } else {
                const value = le.value;
                value.edited = true;
                delete value.version;
                value.timestamp = Date.now();

                data[value.name.toLowerCase()] = value;

                const setValue = jsonEncodeTypes[contentType]?{name:value.name, jsonData:JSON.stringify(value)}:value;

                const ref = doc(this.getEditedContentCollection(contentType, value),nameEncode(value.name.toLowerCase()));
                batch.set(ref, setValue);
            }
            count ++;
            if (count > 450) {
                promises.push(batch.commit().then(function(){
                    //console.log("campaign",t.campaignName,"content updated for", contentType, "and item", value.name);
                }).catch(function (err){
                    console.log("error batch updating campaign content updated for", t.campaignName, list, "error", err);
                    globalDataListener.errorMessage("Error updating content: "+err.message);
                }));
                batch=writeBatch(db);
                count=0;
            }
        }

        if (count) {
            promises.push(batch.commit().then(function(){
                //console.log("campaign",t.campaignName,"content updated for", contentType, "and item", value.name);
            }).catch(function (err){
                console.log("error batch updating campaign content updated for", t.campaignName, list, "error", err);
                globalDataListener.errorMessage("Error updating content: "+err.message);
            }));
        }

        this.resetData();
        const p = Promise.all(promises);
        return p;
    }

    versionUpdateCampaignContent(contentType, value) {
        const oldversion = value.timestamp;
        
        let data = this.editedContent[contentType];
        if (!data) {
            data = this.editedContent[contentType]={};
        }
        
        value.edited = true;
        delete value.version;
        value.timestamp = Date.now();
        data[value.name.toLowerCase()] = value;
        this.resetData(contentType);

        this.recentChanges.push(oldversion);
        this.recentChanges.push(value.timestamp);
        while (this.recentChanges.length > 20) {
            this.recentChanges.shift();
        }
        //console.log("version update", contentType, value);
        this.pushTransactionUpdate(contentType, value.name);
        this.queueVersionUpdate(contentType,value,oldversion);
    }

    queueVersionUpdate(contentType,value, oldversion) {
        if (this.doingTransaction) {
            this.transactionQueue.push({contentType,value, oldversion});
        } else {
            this.doingTransaction=true;
            this.doVersionUpdate(contentType,value, oldversion);
        }
    }

    doVersionUpdate(contentType,value, oldversion) {
        const t=this;
        const db = getFirestore(firebase);
        const setValue = jsonEncodeTypes[contentType]?{name:value.name, jsonData:JSON.stringify(value)}:value
        const docRef = doc(this.getEditedContentCollection(contentType, value),nameEncode(value.name.toLowerCase()));

        runTransaction(db, function(transaction) {
            return new Promise(function (resolve, reject) {
                const ref = transaction.get(docRef);
                ref.then(function(lastDoc) {
                    let oldvalue;
                    if (lastDoc.exists()) {
                        const d = lastDoc.data();
                        if (d.jsonData) {
                            d = JSON.parse(d.jsonData);
                        }
                        oldvalue = d;
                    }
                    if (!oldvalue || !oldversion || t.recentChanges.includes(oldvalue.timestamp)) {
                        transaction.update(docRef, setValue);
                        resolve();
                    } else {
                        t.editedContent[contentType][value.name.toLowerCase()] = oldvalue;
                        t.resetData(contentType);
                        reject(new Error ("change conflict"));
                    }
                },function (err){
                    console.log("error updating campaign content updated for", t.campaignName, contentType, "and item", value.name, "error", err);
                    reject(err);
                });
            });
        }).then(function() {
            if (!t.popTransactionUpdate(contentType, value.name)) {
                getDocFromServer(docRef).then(function (doc) {
                    const data = doc.data();
                    if (data && !t.isTransactionUpdate(contentType,value.name)) {
                        t.editedContent[data.name.toLowerCase()] = data;
                        t.resetData(contentType);
                    }
                });
            }
            if (t.transactionQueue.length) {
                const n = t.transactionQueue.shift();
                t.doVersionUpdate(n.contentType, n.value, n.oldversion);
            } else {
                t.doingTransaction=false;
            }
        },function(error) {
            console.log("error update", value.timestamp, error);
            t.popTransactionUpdate(contentType, value.name)
            if (t.transactionQueue.length) {
                const n = t.transactionQueue.shift();
                t.doVersionUpdate(n.contentType, n.value, n.oldversion);
            } else {
                t.doingTransaction=false;
            }
        });
    }

    pushTransactionUpdate(contentType, name) {
        if (!this.transactionUpdate) {
            this.transactionUpdate = {};
        }
        const fullName = contentType+"-"+name.toLowerCase();
        if (this.transactionUpdate[fullName]) {
            this.transactionUpdate[fullName]++;
        } else {
            this.transactionUpdate[fullName]=1;
        }
    }

    popTransactionUpdate(contentType, name) {
        const fullName = contentType+"-"+name.toLowerCase();
        if (this.transactionUpdate[fullName]>1) {
            this.transactionUpdate[fullName]--;
            return true;
        } else {
            delete this.transactionUpdate[fullName];
            return false;
        }
    }

    isTransactionUpdate(contentType,name) {
        if (!this.transactionUpdate) {
            return false;
        }
        const fullName = contentType+"-"+name.toLowerCase();
        return this.transactionUpdate[fullName];
    }

    deleteCampaignContent(contentType, name, noTombstone){
        const t=this;
        const oname=name;
        let ret = null;

        if (!name) {
            return;
        }
        if (contentType=="players") {
            this.deleteCampaignContent("sharedcharacters",name);
        }
        let data = this.editedContent[contentType];
        if (!data) {
            data = this.editedContent[contentType]={};
        }

        name = name.toLowerCase();
        if (!noTombstone && tombstoneTypes.includes(contentType)) {
            const it = Object.assign({}, data[name]||{name});
            it.deleted = true;
            return this.updateCampaignContent(contentType, it);
        }

        delete data[name];

        ret = deleteDoc(doc(this.getEditedContentCollection(contentType),(contentType=="users")?oname:nameEncode(name))).then(function(){
        }).catch(function (err){
            console.log("error deleting campaign content updated for", t.campaignName, contentType, "and item", name, "error", err);
            //globalDataListener.errorMessage("Error updating content: "+err.message);
        });
        this.resetData(contentType);
        return ret;

    }

    getSharedCharacterInfo(name) {
        return this.editedContent.sharedcharacters[name];
    }

    getAllSharedCharacters() {
        return this.editedContent.sharedcharacters||{};
    }

    getSharedCharacters(p) {
        const t = this;
        return new Promise(function (resolve, reject) {
            p.then(function() {
                t.updateSharedCharacters().then(function() {
                    resolve();
                }).catch(function (err){
                    //reject(err);
                    resolve();
                });
            });
        });
    }

    updateSharedCharacters(notify) {
        const t=this;
        this.unsubscribeSharedCharacters();

        const db = getFirestore(firebase);
        let promises=[],p;

        for (let i in t.editedContent.sharedcharacters) {
            const c = t.editedContent.sharedcharacters[i];

            p = getDocFromServer(doc(db,"users",c.userId,"mycharacters",nameEncode(c.name.toLowerCase()))).then(function(character){
                const data = character.data();

                if (data) {
                    if (data.shareCampaignName && ((data.shareUser ==  campaign.userId) || (data.shareUser == t.prefs.shareUser)) &&
                        ((data.shareCampaignName.toLowerCase() ==t.campaignName.toLowerCase()) || (data.shareCampaignName.toLowerCase() ==(t.prefs.shareCampaignName||"").toLowerCase()))) {
                        t.editedContent.players[c.name.toLowerCase()]=data;
                        if (notify) {
                            t.resetData("players");
                        }
                    }
                }
                subscribeToCharacter();
            },function(err) {
                console.log("could not read data about", c.name, err);
            });
            promises.push(p);

            let retry = 0;

            function subscribeToCharacter() {
                t.unsubscribeSharedCharactersList.push(onSnapshot(doc(db,"users",c.userId,"mycharacters",nameEncode(c.name.toLowerCase())), function(doc) {
                    const data = doc.data();
                    const lowerName = c.name.toLowerCase();

                    if (!data || !data.shareCampaignName || 
                        ((data.shareUser !=  campaign.userId) && (data.shareUser != t.prefs.shareUser)) || 
                        ((data.shareCampaignName.toLowerCase() !=t.campaignName.toLowerCase()) && (data.shareCampaignName.toLowerCase() !=(t.prefs.shareCampaignName||"").toLowerCase()))) 
                    {
                        if (!data) {
                            t.deleteCampaignContent("sharedcharacters", c.name);
                        }
                        if (t.editedContent.players[c.name.toLowerCase()]) {
                            delete t.editedContent.players[c.name.toLowerCase()];
                            t.resetData("players");
                        }
                    } else if (!areSameDeep(t.editedContent.players[lowerName],data)) {
                        const diff = ((t.editedContent.players[lowerName]&&t.editedContent.players[lowerName].timestamp)||0)- (data.timestamp||0);
                        if (diff > 0 && diff < 1000) {
                            //console.log("skipping update since it goes backwards");
                        } else {
                            t.editedContent.players[c.name.toLowerCase()]=data;
                            t.resetData("players");
                        }
                    }
                    retry=10;
                },function(err) {
                    if (retry < 5) {
                        retry++;
                        console.log("doing retry", retry, c.name);
                        subscribeToCharacter();
                    } else {
                        console.log("error listening for data", c.name, err);
                    }
                }));
            }
        }
        return Promise.all(promises);
    }

    onChangeSharedCharacters() {
        const players = this.editedContent.players;
        const sharedcharacters = this.editedContent.sharedcharacters;
        let resetPlayers = false;

        for (let i in players) {
            const p=players[i];

            if (p.sharedAdventureName && !sharedcharacters[p.name.toLowerCase()]) {
                delete players[i];
                resetPlayers=true;
            }
        }
        this.updateSharedCharacters(true);
        if (resetPlayers) {
            this.resetData("players");
        }
    }

}

class campaignInstance {
    constructor(){
        this.unsubscribeList = [];
        this.ecObj = null;
    }

    unsubscribeAll() {
        const u = this.unsubscribeList || [];

        for (let i in u) {
            u[i]();
        }
        this.unsubscribeList = [];
        if (this.unsubscribeShared) {
            this.unsubscribeShared();
            this.unsubscribeShared = null;
            this.adventureViewStarted=false;
        }
        if (this.ecObj){
            this.ecObj.unsubscribeAll();
        }
        this.prefs={};
        this.resetData(null, true);
    }

    loadCampaignData(fnDone, setCampaignName, campaignDisplayName) {
        if (!setCampaignName) {
            setCampaignName = "emptycampaign";
        }
        const t=this;
        const currentUser =campaign.currentUser;
        let callbackDone=false;
        this.unsubscribeAll();
        this.currentCampaign=setCampaignName;
        this.adventureView = null;
        const db = getFirestore(firebase);
        const userId = currentUser && currentUser.uid;
        const isDefaultCampaign = (setCampaignName=="emptycampaign");

        this.resetData(null, true);

        t.loadedContent = {};
        let cget;
        const cdocRef = doc(db,"users",userId,"campaign",nameEncode(t.currentCampaign));
        if (isDefaultCampaign) {
            cget = getDoc(cdocRef);
        } else {
            cget = getDocFromServer(cdocRef);
        }

        cget.then(function (campaignDoc){
            if (campaignDoc.exists()) {
                t.prefs=campaignDoc.data();
                if ((t.prefs.name != t.currentCampaign) || (t.prefs.userDisplayName!=currentUser.displayName) || (t.prefs.email != currentUser.email)) {
                    t.setPrefs({name:t.currentCampaign, userDisplayName:currentUser.displayName,email:currentUser.email});
                }
            } else {
                t.prefs={};
                t.setPrefs({playerMode:isDefaultCampaign,name:setCampaignName, displayName:campaignDisplayName, userDisplayName:currentUser.displayName, email:currentUser.email});
            }
            t.ecObj = new EditedContent(t.currentCampaign, (t.prefs.playerMode && t.prefs.shareUser)?sharedCampaignDataTypes:(isDefaultCampaign?emptyCampaignDataTypes:campaignDataTypes), t.resetData.bind(t), t.prefs);
            t.unsubscribeList.push(onSnapshot(doc(db,"users",userId,"campaign",nameEncode(t.currentCampaign)), function(doc) {
                if (!areSameDeepIgnore(t.prefs,doc.data(),["activeTime", "lastActiveTime"])) {
                    globalDataListener.postEvent("campaignsettings");
                }
                
                t.prefs = doc.data();
            }));

            if (t.prefs.shareUser) {
                const userDoc = doc(db,"users",t.prefs.shareUser,"campaign",nameEncode(t.prefs.shareCampaignName),"users",userId);
                getDocFromServer(userDoc).then(function(cdoc) {
                    if (!cdoc.exists()) {
                        console.log("campaign not found", campaignDisplayName, setCampaignName, campaign);
                        campaign.deleteCampaign(setCampaignName).then(function(){returnError(new Error("Shared Campaign no longer available"));}, function(){returnError(new Error("Shared Campaign no longer available"));});
                    } else {
                        t.unsubscribeList.push(onSnapshot(userDoc,function(doc) {
                            if (!doc.exists()) {
                                console.log("campaign not found", campaignDisplayName, setCampaignName, campaign);
                                campaign.deleteCampaign(setCampaignName);
                            }
                        }));

                        const campaignDoc = doc(db,"users",t.prefs.shareUser,"campaign",nameEncode(t.prefs.shareCampaignName));

                        getDocFromServer(campaignDoc).then(function(doc) {
                            t.gameState = doc.exists()?doc.data():{};
                            t.unsubscribeList.push(onSnapshot(campaignDoc,function(doc) {
                                t.gameState = doc.exists()?doc.data():{};
                                //console.log("updated game state", t.gameState);
                                if (t.prefs?.sharedExtensions != t.gameState?.extensions) {
                                    //console.log("updating cached extensions",t.prefs?.sharedExtensions, t.gameState?.extensions );
                                    t.setPrefs({sharedExtensions:t.gameState.extensions||null});
                                }
                                globalDataListener.postEvent("campaigncontent.adventure");
                            }));
                            //console.log("read game state", t.gameState);
                            finishLoad();
                        },function(err){
                            console.log("campaign game state not found", err, campaignDisplayName, setCampaignName);
                            returnError(err);
                        });
                    }
                },function(err){
                    console.log("campaign error not found", err, campaignDisplayName, setCampaignName);
                    //campaign.deleteCampaign(setCampaignName);
                    returnError(err);
                });


                // check for the campaign name to match.  Update if not
                const campDoc = doc(db,"users",t.prefs.shareUser,"campaign",nameEncode(t.prefs.shareCampaignName));
                t.unsubscribeList.push(onSnapshot(campDoc,function(doc) {
                    if (doc.exists()) {
                        const cdata = doc.data();

                        if (t.prefs && (t.prefs.displayName != cdata.displayName)) {
                            t.setPrefs({displayName:cdata.displayName});
                        }
                    }
                }));
            } else {
                finishLoad();
            }

            function finishLoad() {
                t.ecObj.load().then(function () {
                    campaign.checkCampaigns();
                    t.fetchCampaignPackages().then(function () {;
                        if (!callbackDone) {
                            callbackDone=true;
                            fnDone();
                        }
                        t.fixupNPCs();
                    }).catch(returnError);
                }).catch(returnError);
            }
        }).catch(returnError);

        function returnError(err) {
            console.log("could not read campaign data", t.currentCampaign, err);
            if (!callbackDone) {
                callbackDone=true;
                fnDone(err);
            }
        }

    }

    fixupNPCs() {
        if ((this.prefs?.playerMode == "ruleset") && this.ecObj.editedContent.npcs) {
            const {npcs} =this.ecObj.editedContent;
            //console.log("found incorrect npcs", npcs);

            for (let i in npcs) {
                const npc = npcs[i];
                //console.log("moving", npc.name);
                campaign.updateCampaignContent("monsters", npc).then(function (){
                    campaign.deleteCampaignContent("npcs", npc.name);
                });
            
            }
        }
    }

    loadWatchCampaignData(fnDone, id) {
        const t=this;
        let callbackDone=false;
        this.unsubscribeAll();
        this.adventureView = null;
        const db = getFirestore(firebase);

        this.resetData(null, true);

        t.loadedContent = {};

        getDocFromServer(doc(db,"watchinvites",id)).then(function (watchInvite){
            if (!watchInvite.exists()) {
                returnError(new Error("Link has expired."));
                return;
            }
            const wdata = watchInvite.data();

            getDocFromServer(doc(db,"purchases",wdata.user)).then(function (watchPurchasesDoc){
                if (enforcePermissions) {
                    if (!watchPurchasesDoc.exists()) {
                        returnError(new Error("Watch mode not enabled for the user account."));
                        return;
                    } else {
                        const wp = watchPurchasesDoc.data();
                        const watchModeAllowed = wp.watchModeAllowed;
                        const minWatchMode = (wdata.user != campaign.userId)?2:1;
                        if (!watchModeAllowed || (watchModeAllowed < minWatchMode)) {
                            returnError(new Error("Watch not enabled for the user account."));
                            return;
                        }
                    }
                }
                t.unsubscribeList.push(onSnapshot(doc(db,"watchinvites",id),function(doc) {
                    if (!doc.exists()) {
                        globalDataListener.sendStopWatch(id);
                        console.log("watch link no longer found", id);
                    }
                }));

                t.currentCampaign=wdata.campaign;
                t.watchKey = id;
                t.prefs = {
                    name:wdata.campaign, 
                    displayName:wdata.campaignDisplayName, 
                    playerMode:true,
                    shareUser:wdata.user,
                    shareCampaign:nameEncode(wdata.campaign),
                    shareCampaignName:wdata.campaign,
                };

                t.ecObj = new EditedContent(t.currentCampaign, sharedCampaignDataTypes, t.resetData.bind(t), t.prefs);

                const userDoc = doc(db,"users",t.prefs.shareUser,"campaign",nameEncode(t.prefs.shareCampaignName));
                getDocFromServer(userDoc).then(function(doc) {
                    if (!doc.exists()) {
                        console.log("campaign not found", campaignDisplayName);
                        returnError(new Error("Game no longer available"));
                    } else {
                        finishLoad();
                    }
                },function(err){
                    console.log("campaign error not found", err, campaignDisplayName, setCampaignName);
                    //campaign.deleteCampaign(setCampaignName);
                    returnError(err);
                });

                function finishLoad() {
                    t.ecObj.load().then(function () {
                        campaign.checkCampaigns();
                        t.fetchCampaignPackages().then(function () {;
                            if (!callbackDone) {
                                callbackDone=true;
                                fnDone();
                            }
                        }).catch(returnError);
                    }).catch(returnError);
                }
            }, returnError);
        }).catch(returnError);

        function returnError(err) {
            console.log("could not read campaign data", t.currentCampaign, err);
            if (!callbackDone) {
                callbackDone=true;
                fnDone(err);
            }
        }
    }

    async fetchCampaignPackages() {
        const t=this;
        const db = getFirestore(firebase);
        const users = [];
        const promises=[];
        let loaded;

        if (this.prefs.playerMode && this.prefs.shareUser) {
            users.push(this.prefs.shareUser);
        }

        for (let i in this.editedContent.users) {
            const u = this.editedContent.users[i];
            if (!users.includes(u.user)) {
                users.push(u.user);
            }
        }

        if (!this.savePurchases) {
            this.savePurchases = {};
        }
    
        for (let u of users) {
            const userPurchases = doc(db,"purchases",u);

            const prom = getDocFromServer(userPurchases).then(function (doc) {
                let campaignPackages;
                if (doc.exists()) {
                    campaignPackages = doc.data()||{packageList:[]};
                } else {
                    campaignPackages = {packageList:[]};
                }
                t.savePurchases[u] = campaignPackages;
                loaded=true;

                t.unsubscribeList.push(onSnapshot(userPurchases,function (doc){
                    let campaignPackages;
                    if (doc.exists()) {
                        campaignPackages = doc.data()||{packageList:[]};
                    } else {
                        campaignPackages = {packageList:[]};
                    }
    
                    if (!areSameDeep(t.savePurchases[u],campaignPackages)) {
                        t.savePurchases[u] = campaignPackages;
                        t.calculateCampaignPurchasePackages();
        
                        t.loadCampaignPackages().then(function () {
                            globalDataListener.postEvent("campaignsettings");
                            campaign.resetData();
                        }, function (err) {
                            console.log("error loading packages", err);
                        });
                    }
                }), function (err) {if (err) console.log("purchases onsnapshot error", err)});
            });
            promises.push(prom);

        }

        await Promise.all(promises);
        if (loaded) {
            this.calculateCampaignPurchasePackages();
            await this.loadCampaignPackages();
//            globalDataListener.postEvent("campaignsettings");
//            campaign.resetData(null, true);
        }
    }

    calculateCampaignPurchasePackages() {
        const prefs = this.prefs||{};
        const campaign = prefs.playerMode?prefs.shareCampaignName:this.currentCampaign;
        let campaignPurchasePackages = [];

        for (let i in this.editedContent.users) {
            const u = (this.editedContent.users[i]||{}).user;
            const campaignPackages = this.savePurchases[u];
            if (campaignPackages && (campaignPackages.shareCampaigns||[]).includes(campaign)) {
                campaignPurchasePackages = computePurchasePackages(campaignPackages, campaignPurchasePackages);
            }
        }

        if (prefs.playerMode && prefs.shareUser) {
           const campaignPackages = this.savePurchases[prefs.shareUser];
            if (campaignPackages) {
                campaignPurchasePackages = computePurchasePackages(campaignPackages, campaignPurchasePackages);
            }
        }
        this.campaignPurchasePackages= campaignPurchasePackages;
    }

    getSharing() {
        const sharing = {isShared:false, isShareable:false};
        const prefs = this.prefs||{};

        if (!(prefs.playerMode && prefs.shareUser)) {
            return sharing;
        }
        const gmProducts = (this.savePurchases[prefs.shareUser]||{}) || {};
        const shareCampaigns = campaign.getShareCampaigns();
        const maxShareCount = campaign.ownedPackages.maxShareCount||1;
        //console.log("maxShareCount", maxShareCount, campaign.ownedPackages);
        
        sharing.isShared = shareCampaigns.includes(prefs.shareCampaignName);

        const allowLibraySharing = campaign.ownedPackages.allowLibraySharing;
        sharing.noAllowLibrarySharing = !(allowLibraySharing || gmProducts.allowLibraySharing);
        sharing.isShareable = (allowLibraySharing || gmProducts.allowLibraySharing) && (maxShareCount>shareCampaigns.length);
        sharing.needSubscription = (!(allowLibraySharing || gmProducts.allowLibraySharing)) || (maxShareCount <5);

        //console.log("sharing", sharing, gmProducts, campaign.ownedPackgaes);
        return sharing;
    }

    loadCampaignPackages() {
        if (this.ecObj && this.ecObj.editedContent) {
            return loadPackages(this.campaignPurchasePackages||[], {});
        }
    }

    getMRUList(mrulist, ignore) {
        let mru = this.prefs[mrulist]||[];

        if (ignore) {
            const fi = mru.findIndex(function (m) {return m.description==ignore});
            if (fi >= 0) {
                mru = mru.concat([]);
                mru.splice(fi, 1);
            }
        }
        return mru;
    }

    addMRUList(mrulist, a) {
        let mru = (this.prefs[mrulist]||[]).concat([]);
        let ns ={};

        const fi = mru.findIndex(function (m) {return m.description==a.description});
        if (fi==0){
            // already in first spot
            return;
        }
        if (fi >= 0) {
            mru.splice(fi, 1);
        }
        mru.unshift(a);
        if (mru.length > 10) {
            mru = mru.slice(0,9);
        }

        ns[mrulist] = mru;
        this.setPrefs(ns);
    }

    getCurrentCampaign() {
        return this.currentCampaign;
    }

    getWatchKey() {
        return this.watchKey;
    }

    getPrefs() {
        return this.prefs||{};
    }

    isPlayerMode() {
        return this.getPrefs().playerMode||false;
    }

    setPrefs(prefs) {
        if (this.prefs) {
            const db = getFirestore(firebase);
            const userId = campaign.userId;

            Object.assign(this.prefs, prefs);
            const p= setDoc(doc(db,"users",userId,"campaign",nameEncode(this.currentCampaign)),this.prefs, {merge:false}).catch(function(err){
                console.log("error updating prefs", err);
                globalDataListener.errorMessage("Error updating content: "+err.message);
            });
            campaign.warnNetwork(p);

            if ((prefs.deactivatedPackages!==undefined) || (prefs.selectedPackages!==undefined) || (prefs.disabled!==undefined)) {
                campaign.resetData();
            }
            globalDataListener.postEvent("campaignsettings");
        }
    }

    getUserPackages() {
        if (!this.userPackages) {
            const pkgs = [];
            for (let i in this.editedContent.users) {
                const u = this.editedContent.users[i];
                if (u.userPkg) {
                    const pkg = JSON.parse(u.userPkg);
                    pkgs.push(pkg);
                }
            }
            this.userPackages = pkgs;
        }
        return this.userPackages;
    }

    getCurrentPackageList() {
        if (this.cachedPackageList) {
            return this.cachedPackageList;
        }
        const list=[];
        const userPkgs = this.getUserPackages();

        if (!this.editedPackage) {
            //compute package for edited content
            const pkg = {};
            const editedContent = campaign.userEditedContent();

            if (editedContent) {
                for (let c in editedPackageDataTypes)
                {
                    const content = editedContent[editedPackageDataTypes[c]];
                    if (content) {
                        const nl = pkg[editedPackageDataTypes[c]] = [];
                        for (let i in content) {
                            nl.push(content[i]);
                        }
                    }
                }
            }
            this.editedPackage = pkg;
        }

        if (this.prefs.shareUser && this.prefs.playerMode && !this.editedSharedPackage) {
            //compute package for edited content
            const pkg = {};
            const editedContent = this.ecObj.editedContent;
            const sharedEdited = {};

            for (let c in editedPackageDataTypes)
            {
                const content = editedContent[editedPackageDataTypes[c]];
                if (content) {
                    const nl = pkg[editedPackageDataTypes[c]] = [];
                    for (let i in content) {
                        const it = content[i];
                        sharedEdited[it.name]=1;
                        nl.push(it);
                    }
                }
            }
            this.sharedEdited = sharedEdited;
            this.editedSharedPackage = pkg;
        }

        const advSettings = this.getGameState();
        let campaignDisabled=advSettings?.disabled || {};
        let campaignSelected=advSettings?.selectedPackages;
        const playerModeShared = this.prefs.playerMode && this.prefs.shareUser;

        {
            const prefs = campaign.isDefaultCampaign()?{}:this.prefs;
            const deactivatedPackages = prefs.deactivatedPackages||{};
            const selectedPackages = prefs.selectedPackages;
            const pkgs = campaign.ownedPurchasedPackages||[];
            const globalDisabledpackages = campaign.getUserSettings().disabledPackages||{};
            const ownedDisabled = globalDisabledpackages.owned || {};

            for (let pi in pkgs) {
                const p = pkgs[pi];
                const pid = p.id;
                if (!ownedDisabled[pid] && !deactivatedPackages[pid]) {
                    addToList(p,(!campaignDisabled[pid] && (!campaignSelected || campaignSelected[pid]) && (!selectedPackages || selectedPackages[pid]))?null:allNoBrowse);
                }
            }
        }
        
        const pkgs = this.campaignPurchasePackages||[];
        for (let pi in pkgs) {
            const p = pkgs[pi];
            if (!campaignDisabled[p.id]) {
                addToList(p,(campaignSelected && !campaignSelected[p.id])?allNoBrowse:this.prefs.playerMode?noPlayersBrowse:null);
            }
        }

        for (let i in campaign.myPackages) {
            const p = campaign.myPackages[i];
            if (!p.majorVersion) {
                const pkg = campaign.myLoadedPackages[i];
                if (pkg) {
                    addToList(null, null, pkg);
                }
            }
        }

        if (this.editedSharedPackage){
            addToList(null, noPlayersBrowse, this.editedSharedPackage);
        }
        addToList(null, null, this.editedPackage, playerModeShared);
        for (let i in userPkgs) {
            const pkg = userPkgs[i];
            addToList(null, allNoBrowse, pkg, true);
        }

        this.cachedPackageList=list;
        return list;

        function addToList(p, skipBrowse, pkg,alwaysDemote) {
            if (pkg) {
                list.push({pkg,skipBrowse,alwaysDemote});
                return;
            }
            const pos = list.findIndex(function (a) {return !a.entryIds && !p.entryIds && !a.entryTypes && !p.entryTypes && a.pkg && (a.pkg.id==p.id)});
            if (pos < 0) {
                const pkg = getPackage(p.id);
                if (pkg) {
                    list.push(Object.assign({pkg, skipBrowse,alwaysDemote},p));
                } else {
                    console.log("could not find package", p);
                }
            } else {
                //console.log("found dup", p)
            }
        }
    }

    isSharedEdited(name) {
        if (!this.editedSharedPackage) {
            this.getCurrentPackageList();
        }
        return (this.sharedEdited||{})[name];
    }

    isCampaignPackage(pid) {
        const pkgs = this.campaignPurchasePackages||[];

        const ownedDisabled = ((this.ecObj.editedContent.adventure||{}).settings||{}).disabled || {};
        if (ownedDisabled[pid]) {
            return false;
        }
        const pos = pkgs.findIndex(function (p) {return ((p.id == pid) && !p.entryIds && !p.entryTypes)});
        return (pos>=0);
    }

    getAvailablePackageList() {
        const map={};
        const list=[];

        const pkgs = campaign.ownedPurchasedPackages||[];
        const pkgsShared = this.campaignPurchasePackages||[]
        const globalDisabledpackages = campaign.getUserSettings().disabledPackages||{};
        const ownedDisabled = globalDisabledpackages.owned || {};
        for (let pi in pkgs) {
            const p = pkgs[pi].id;
            if (!ownedDisabled[p] && loadedPackages[p]) {
                map[p]=loadedPackages[p];
            }
        }

        for (let pi in pkgsShared) {
            const p = pkgsShared[pi].id;
            if (loadedPackages[p]) {
                map[p]=loadedPackages[p];
            }
        }

        for (let i in map) {
            list.push(map[i]);
        }
        return list;
    }

    getEditedPackage() {
        this.getCurrentPackageList();
        return this.editedPackage;
    }

    resetData(contentType, skipNotify){
        this.cachedPackageList = null;
        // reset calc'ed data
        if (!contentType || contentType=="users") {
            this.userList=null;
            this.userPackages=null;

            //reset content type to force a reload since users contains the user packages
            contentType=null;
        }

        if (!contentType || contentType=="monsters" || contentType=="npcs") {
            this.allMonsters = null;
            this.monsterNamesList = null;
            this.npcList = null;
        }
        
        if (!contentType || contentType=="chat") {
            this.chatList = null;
        }
        
        if (!contentType || contentType=="books") {
            this.bookList = null;
            this.bookFragments = null;
            this.books = null;
            this.booksList = null;
        }
        
        if (!contentType || contentType=="items") {
            this.allItems = null;
            this.sortedItems = null;
        }

        if (!contentType || contentType=="feats") {
            this.allFeats = null;
            this.sortedFeats = null;
        }

        if (!contentType || contentType=="extensions") {
            this.allExtensions = null;
        }

        if (!contentType || contentType=="randomtables") {
            this.allRandomTables = null;
        }
        if (!contentType || contentType=="backgrounds") {
            this.allBackgrounds = null;
            this.sortedBackgrounds = null;
        }

        if (!contentType || contentType=="maps") {
            this.allMaps = null;
        }

        if (!contentType || contentType=="objects") {
            this.allObjects = null;
        }

        if (!contentType || contentType=="art") {
            this.allArt = null;
        }

        if (!contentType || contentType=="audio") {
            this.allAudio = null;
        }

        if (!contentType || contentType=="pins") {
            this.allPins = null;
            this.sortedPins=null;
        }

        if (!contentType || contentType=="plannedencounters") {
            this.allPlannedEncounters = null;
        }

        if (!contentType || contentType=="players") {
            this.savedPlayerList=null;
        }
        
        if (!contentType || contentType=="mycharacters") {
            campaign.savedMyCharactersList=null;
            campaign.savedPregenList=null;
            campaign.allCharacters=null;
        }
        
        if (!contentType || contentType=="adventure") {
            this.pingList=null;
            this.loadCampaignPackages();
        }

        if (!contentType || contentType=="customTypes") {
            this.customTypes=null;
        }
        
        if (!contentType || contentType=="classes") {
            this.classes=null;
            this.subclasses=null;
        }
        
        if (!contentType || contentType=="races") {
            this.races=null;
        }
        
        if (!contentType || contentType=="spells" || contentType=="classes") {
            this.allSpells = null;
            this.spellNamesList = null;
        }

        if (!contentType || contentType=="journal") {
            this.journalList=null;
        }
        
/*        
        if (!contentType || contentType=="sounds") {
            this.soundsList=null;
        }
*/
        
        this.editedPackage = null;
        this.editedSharedPackage = null;
        this.allContent = null;
        if (contentType=="npcs") {
            contentType = "monsters";
        }
        if (!skipNotify) {
            globalDataListener.postEvent("campaigncontent.");
            if (contentType) {
                globalDataListener.postEvent("campaigncontent."+contentType);
            } else {
                for (let i in editedDataTypes) {
                    globalDataListener.postEvent("campaigncontent."+editedDataTypes[i]);
                }
            }
        }
    }

    getSharedCharacterInfo(name) {
        return this.ecObj.getSharedCharacterInfo(name);
    }

    getAllSharedCharacters() {
        return this.ecObj.getAllSharedCharacters();
    }

    updateCampaignContent(contentType, value) {
        return this.ecObj.updateCampaignContent(contentType, value);
    }

    versionUpdateCampaignContent(contentType, value) {
        return this.ecObj.versionUpdateCampaignContent(contentType, value);
    }

    deleteCampaignContent(contentType, name, noTombstone){
        return this.ecObj.deleteCampaignContent(contentType, name, noTombstone);
    }

    get editedContent() {
        return this.ecObj.editedContent;
    }

    updateAdventureView(value){
        const t=this;
        const db = getFirestore(firebase);
        const userId = campaign.userId;
        delete value.version;
        value.timestamp=Date.now();

        setDoc(doc(db,"users",userId,"campaign",nameEncode(t.currentCampaign),"shared","projectedview"),value, {merge:true}).catch(function (err){
            console.log("error updating campaign projected view", t.currentCampaign, "error", err);
            globalDataListener.errorMessage("Error updating content: "+err.message);
        });
        if (this.adventureViewStarted) {
            //console.log("directUpdate");
            if (!this.adventureView) {
                this.adventureView = {};
            }
            Object.assign(this.adventureView, value);
            globalDataListener.postEvent("campaigncontent.adventureview");
        }
    }

    getUserList() {
        if (!this.userList) {
            const l=[];
            for (let i in this.editedContent.users) {
                l.push(this.editedContent.users[i]);
            }
            l.sort(sortDisplayName);
            this.userList = l;
        }
        return this.userList;
    }

    getUserInfo(name) {
        if (!name || !this.editedContent.users) {
            return null;
        }
        return this.editedContent.users[name.toLowerCase()];
    }

    startAdventureView() {
        if (this.adventureViewStarted) {
            return;
        }
        const t = this;
        const db = getFirestore(firebase);
        const playerMode = this.prefs.playerMode;

        const userId=playerMode?this.prefs.shareUser:campaign.currentUser.uid;
        const campaignName=playerMode?this.prefs.shareCampaignName:this.currentCampaign;

        if (!campaignName) {
            console.log("no adventure to start");
            return;
        }
        this.adventureViewStarted = true;
        this.unsubscribeShared = onSnapshot(doc(db,"users",userId,"campaign",nameEncode(campaignName),"shared","projectedview"),function (doc) {
            const d= doc.data();
            const diff = (t.adventureView?.timestamp||0)- (d.timestamp||0);
            if (diff > 0) {
                //console.log("skipping adventure update since it recently goes backwards", diff);
            } else {
                if (!areSameDeep(d, t.adventureView)) {
                    globalDataListener.postEvent("campaigncontent.adventureview");
                } else {
                    //console.log("no change adventure update");
                }
                t.adventureView = d;
            }

        }, function (err) {if (err) console.log("start adventure onsnapshot error", err)});
    }

    getPingList() {
        if (!this.pingList) {
            const l=[];
            for (let i in this.editedContent.adventure) {
                const p = this.editedContent.adventure[i];
                if (p.type=="ping") {
                    l.push(p);
                }
            }
            this.pingList = l;
        }
        return this.pingList;
    }

    getAdventureView() {
        this.startAdventureView();
        return this.adventureView || {};
    }

    getCampaignDice() {
        if (!this.editedContent.adventure) {
            return {name:'campaigndice'};
        }
        return this.editedContent.adventure['campaigndice'] || {name:'campaigndice'};
    }

    getCampaignSettings() {
        if (!this.editedContent.adventure) {
            return {name:'settings'};
        }
        return this.editedContent.adventure['settings'] || {name:'settings'};
    }

    getGameState() {
        const prefs = this.getPrefs();
        const mstate = (prefs.shareUser?this.gameState:prefs)||{};
        if (mstate.gsv>=2) {
            return mstate;
        }

        const state = {};
        Object.assign(state, mstate);
        this.copyAdventureGameState(state);
        //console.log("gamestate", state);
        return state;
    }

    copyAdventureGameState(state) {
        if (this.editedContent.adventure) {
            const adv = this.editedContent.adventure['settings'];

            if (adv) {
                if (adv.extensions != undefined) {
                    state.extensions = adv.extensions;
                }

                if (adv.preferred != undefined) {
                    state.preferred = adv.preferred;
                }

                if (adv.disallowed != undefined) {
                    state.disallowed = adv.disallowed;
                }

                if (adv.disabled != undefined) {
                    state.disabled = adv.disabled;
                }

                if (adv.sharing != undefined) {
                    state.sharing = adv.sharing;
                }
                
                if (adv.names != undefined) {
                    state.names = adv.names;
                }

                if (adv.differentMonsterInitiatives != undefined) {
                    state.differentMonsterInitiatives=adv.differentMonsterInitiatives;
                }
            }
        }

    }

    updateGameState(state) {
        const {shareUser, gsv} = this.getPrefs();
        if (shareUser) {
            throw new Error("players can't change state");
        }
        const newSettings = {gsv:2};
        if (!(gsv>=2)) {
            this.copyAdventureGameState(newSettings);
        }
        Object.assign(newSettings, state);
        this.setPrefs(newSettings);

        // update adventure settings as well
        //const newAdv = Object.assign({}, (this.editedContent?.adventure['settings'])||{name:"settings"});
        //Object.assign(newAdv, newSettings);
        //campaign.updateCampaignContent("adventure", newAdv);
    }

    getWallpaper() {
        if (!campaign.isDefaultCampaign()) {
            const {extensions, wallpaper} = this.getGameState();

            const gsArt = this.getArtInfo(wallpaper);
            if (gsArt?.url) {
                return gsArt.url;
            }
            
            for (let i in extensions) {
                const e = this.getExtensionInfo(extensions[i]);
                if (e?.wallpaper) {
                    const art = this.getArtInfo(e.wallpaper);
                    if (art?.url) {
                        return art.url;
                    }
                }
            }
        }

        const asArt = campaign.getArtInfo(campaign.getUserSettings().wallpaper);
        if (asArt?.url) {
            return asArt.url;
        }
        return null;
    }

    buildBooks() {
        if (!this.books){
            const booksList = [];
            const books = {};
            const noBrowseBooks={};
            const demoteBooks={};
            const bookFragments = {};

            const packageList = this.getCurrentPackageList();
            const isdefaultcampaign = campaign.isDefaultCampaign();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.books;

                if (p.books) {
                    for (let e in p.books){
                        const b = p.books[e];

                        if (b.type == "fragment") {
                            addEntryToSet(bookFragments, b, null, "Books", pinfo);
                        } else if (b.type == "book") {
                            if (!b.displayName) {
                                b.displayName=b.name;
                            }
                            const cur = books[b.name?.toLowerCase()];

                            if (isdefaultcampaign && b.edited && b.buildMap && cur && areSameDeepIgnore(cur, b, ["version","timestamp","buildMap","edited"])) {
                                console.log("cleanup trival book edit", b.displayName);
                                setTimeout(function (){
                                    campaign.deleteCampaignContent("books", b.name);
                                }, 100);
                            }
                            addEntryToSets(books, noBrowseBooks, demoteBooks, canbrowse, b, null, "Books", pinfo);
                        }
                    }            
                }
            }

            for (let i in books) {
                booksList.push(books[i]);
            }
            booksList.sort(sortDisplayName);
            this.allBooks = Object.assign(Object.assign(Object.assign({}, demoteBooks), noBrowseBooks), books);
            this.booksList = booksList;
            this.books = books;
            this.bookFragments = bookFragments;
            this.noBrowseBooks = noBrowseBooks;
            this.demoteBooks = demoteBooks;
            //console.log("books", booksList, this.allBooks, books, noBrowseBooks, demoteBooks, this.cachedPackageList);
        }
    }
    
    getBookList(){
        this.buildBooks();
        return this.booksList;
    }

    getAllBooks(){
        this.buildBooks();
        return this.allBooks;
    }

    getBookInfo(book){
        if (!book) {
            return null;
        }
        this.buildBooks();

        book = book.toLowerCase();
        return this.noBrowseBooks[book] || this.books[book] || this.demoteBooks[book];
    }

    getVisibleBooks() {
        this.buildBooks();

        return this.books;
    }

    getBookFragment(name) {
        if (!name) {
            return null;
        }
        this.buildBooks();

        return this.bookFragments[name];;
    }

    buildMaps() {
        if (!this.allMaps) {
            const maps = {};
            const noBrowseMaps = {}
            const demoteMaps = {}
            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p=pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.maps;

                if (p.maps) {
                    for (let e in p.maps){
                        const x = p.maps[e];
                        if (!x.displayName) {
                            x.displayName = x.name;
                        }
                        addEntryToSets(maps, noBrowseMaps,demoteMaps, canbrowse, x, null, "Maps", pinfo);
                    }            
                }
            }
            this.allMaps = maps;
            this.noBrowseMaps= noBrowseMaps;
            this.demoteMaps = demoteMaps;
        }
        return this.allMaps;
    }

    getMaps() {
        const allMaps = this.buildMaps();
        const maps=[];

        for (let i in allMaps){
            maps.push(allMaps[i]);
        }

        maps.sort(sortDisplayName);
        return maps;
    }

    getMapInfo(mapname) {
        if (!mapname)
            return null;
        mapname=mapname.toLowerCase();
        this.buildMaps();
        return this.noBrowseMaps[mapname] || this.allMaps[mapname] || this.demoteMaps[mapname];
    }

    getMapExtraInfo(mapname) {
        if (!mapname || !this.editedContent.mapextra)
            return null;
        return this.editedContent.mapextra[mapname.toLowerCase()];
    }

    buildArt() {
        if (!this.allArt) {
            const vals = {};
            const noBrowseArt = {};
            const demoteArt = {};
            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p=pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.art;

                const l = p.art;
                if (l) {
                    for (let e in l){
                        const x = l[e];
                        addEntryToSets(vals, noBrowseArt, demoteArt, canbrowse, x, null, "Art", pinfo);
                    }            
                }
            }
            this.allArt = vals;
            this.noBrowseArt = noBrowseArt;
            this.demoteArt = demoteArt;
        }
        return this.allArt;
    }

    getArt() {
        let allArt = this.buildArt();
        let art=[];

        for (let i in allArt){
            art.push(allArt[i]);
        }

        art.sort(sortDisplayName)
        return art;
    }

    getArtInfo(name) {
        if (!name)
            return null;
        this.buildArt()
        return this.noBrowseArt[name] || this.allArt[name] || this.demoteArt[name];
    }

    buildAudio() {
        if (!this.allAudio) {
            const vals = {};
            const noBrowseAudio = {};
            const demoteAudio = {};
            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p=pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.audio;

                const l = p.audio;
                if (l) {
                    for (let e in l){
                        const x = l[e];
                        addEntryToSets(vals, noBrowseAudio, demoteAudio, canbrowse, x, null, "Audio", pinfo);
                    }            
                }
            }
            this.allAudio = vals;
            this.noBrowseAudio = noBrowseAudio;
            this.demoteAudio = demoteAudio;
            this.audioList = null;
        }
        return this.allAudio;
    }

    getAudio() {
        let allAudio = this.buildAudio();
        if (!this.audioList) {
            const audio=[];

            for (let i in allAudio){
                audio.push(allAudio[i]);
            }

            audio.sort(sortDisplayName)
            this.audioList = audio;
        }
        return this.audioList;
    }

    getAudioInfo(name) {
        if (!name)
            return null;
        this.buildAudio()
        return this.noBrowseAudio[name] || this.allAudio[name] || this.demoteAudio[name];
    }

    buildRandomTables() {
        if (!this.allRandomTables) {
            const vals = {};
            const noBrowseRandomTables = {};
            const demoteRandomTables = {};
            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p=pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.randomtables;

                const l = p.randomtables;
                if (l) {
                    for (let e in l){
                        const x = l[e];
                        addEntryToSets(vals, noBrowseRandomTables, demoteRandomTables, canbrowse, x, null, "Random Encounter Tables", pinfo);
                    }            
                }
            }
            this.allRandomTables = vals;
            this.noBrowseRandomTables = noBrowseRandomTables;
            this.demoteRandomTables = demoteRandomTables;
        }
        return this.allRandomTables;
    }

    getRandomTables() {
        let allRandomTables = this.buildRandomTables();
        let randomtables=[];

        for (let i in allRandomTables){
            randomtables.push(allRandomTables[i]);
        }

        randomtables.sort(sortDisplayName);
        return randomtables;
    }

    getRandomTableInfo(name) {
        if (!name)
            return null;
        this.buildRandomTables();
        return this.noBrowseRandomTables[name] || this.allRandomTables[name] || this.demoteRandomTables[name];
    }

    getSortedPinsList() {
        if (!this.sortedPins || !this.allPins) {
            const pins = this.getPins();
            const itl = [];

            for (let i in pins) {
                itl.push(pins[i]);
            }
            itl.sort(sortDisplayName)
            this.sortedPins = itl;
        }
        return this.sortedPins;
    }

    getPins() {
        if (!this.allPins) {
            const vals = {};
            const noBrowsePins = {};
            const demotePins = {};
            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.pins;

                const l = p.pins;
                if (l) {
                    for (let e in l){
                        const x = l[e];
                        if (!x.displayName) {
                            x.displayName = x.name;
                        }
                        if (!x.deleted) {
                            addEntryToSets(vals, noBrowsePins, demotePins, canbrowse, x, null, "Pins", pinfo);
                        } else {
                            delete vals[x.name.toLowerCase()];
                        }
                    }            
                }
            }
            this.allPins = vals;
            this.noBrowsePins = noBrowsePins;
            this.demotePins = demotePins;
        }
        return this.allPins;
    }

    getPinInfo(pinname) {
        if (!pinname)
            return null;
        pinname = pinname.toLowerCase();
        this.getPins();
        return this.noBrowsePins[pinname] || this.allPins[pinname] || this.demotePins[pinname];
    }

    findEncounterPin(encounterName) {
        if (!encounterName) {
            return null;
        }
        const encounter = this.getPlannedEncounterInfo(encounterName);
        if (encounter && encounter.pinName) {
            return this.getPinInfo(encounter.pinName);
        }

        const pins=this.getPins();
        encounterName = encounterName.toLowerCase();

        for (let i in pins) {
            const links = pins[i].links;
            for (let p in links) {
                if (links[p].type=="encounter" && links[p].name.toLowerCase()==encounterName) {
                    return pins[i];
                }
            }
        }
        return null;
    }

    getAdventure() {
        if (!this.editedContent.adventure) {
            return {name:'default'};
        }
        return this.editedContent.adventure['default'] || {name:'default'};
    }

    updateAdventure(update) {
        const def = Object.assign({},this.editedContent.adventure.default);
        for (let i in update) {
            const split = i.split(".");
            if (split.length > 2) {
                throw ("only update two part")
            }
            if (split.length == 1) {
                def[split[0]]=update[i];
            } else {
                if (!def[split[0]]) {
                    def[split[0]]={};
                }
                def[split[0]][split[1]]=update[i];
            }
        }
        let now = Date.now();
        if (def.timestamp &&  now < def.timestamp) {
            now = def.timestamp+1;
        }
        def.timestamp=now;
        update.timestamp=now;

        this.editedContent.adventure.default=def;
        const ret = updateDoc(doc(this.ecObj.getEditedContentCollection("adventure"),"default"),update).then(function(){
            //console.log("campaign",t.campaignName,"content updated for", contentType, "and item", value.name);
        }).catch(function (err){
            console.log("error updating adventure content", err, update);
            globalDataListener.errorMessage("Error updating content: "+err.message);
        });
        this.resetData("adventure");
        return ret;
    }

    getSharedTreasure() {
        const defaultVal = {name:"sharedtreasure", treasure:{}};

        if (!this.editedContent.adventure) {
            return defaultVal;
        }
        return this.editedContent.adventure['sharedtreasure'] || defaultVal;
    }

    getHandouts() {
        if (!this.editedContent.adventure) {
            return {name:'handouts'};
        }
        return this.editedContent.adventure['handouts'] || {name:'handouts'};
    }

    getEncountersHistory() {
        let ret=[];

        for (let i in this.editedContent.encountershistory){
            const h = this.editedContent.encountershistory[i];
            if (!h.displayName) {
                h.displayName = h.name;
            }
            ret.push(h);
        }

        ret.sort(function(a,b){
            return b.timeFinished - a.timeFinished;
        });
        return ret;
    }

    getEncounterHistoryInfo(name) {
        if (!name || !this.editedContent.encountershistory) {
            return null;
        }
        const h = this.editedContent.encountershistory[name.toLowerCase()];
        if (h && !h.displayName) {
            h.displayName = h.name;
        }
        return h;
    }

    getChat() {
        if (!this.chatList) {
            let ret=[];

            const nowTimestamp = Timestamp.now();

            for (let i in this.editedContent.chat){
                let h = this.editedContent.chat[i];
                if (!h.time || !h.time.nanoseconds) {
                    //console.log('fixup time', h, h.time);
                    h = Object.assign({}, h);
                    if (h.timestamp) {
                        h.time = Timestamp.fromMillis(h.timestamp+campaign.serverTimeSkew);
                    } else {
                        h.time = nowTimestamp;
                    }
                }
                ret.push(h);
            }

            ret.sort(function(a,b){const sd=(a.time.seconds - b.time.seconds); if (sd!=0) return sd; return a.time.nanoseconds-b.time.nanoseconds});
            this.chatList = ret;
            if (ret.length > 100) {
                setTimeout(function (){
                    campaign.deleteCampaignContent("chat", ret[0].name);
                }, 100);
            }this
        }
        return this.chatList;
    }

    getChatInfo(name) {
        if (!name || !this.editedContent.chat) {
            return null;
        }
        const h = this.editedContent.chat[name];
        return h;
    }

    getJournal() {
        if (!this.journalList) {
            let ret=[];

            for (let i in this.editedContent.journal){
                let h = this.editedContent.journal[i];
                ret.push(h);
            }

            ret.sort(function(a,b){return ((b.timestamp||0) - (a.timestamp||0))});
            this.journalList = ret;
        }
        return this.journalList;
    }

    getJournalInfo(name) {
        if (!name || !this.editedContent.journal) {
            return null;
        }
        const h = this.editedContent.journal[name];
        return h;
    }

    getSounds() {
        let ret=[];

        for (let i in this.editedContent.sounds){
            let h = this.editedContent.sounds[i];
            ret.push(h);
        }

        return ret;
    }

    getSoundsInfo(name) {
        if (!name || !this.editedContent.sounds) {
            return null;
        }
        const h = this.editedContent.sounds[name];
        return h;
    }

    buildPlannedEncounters() {
        if (!this.allPlannedEncounters) {
            const vals = {};
            const noBrowsePlannedEncounters = {};
            const demoteEncounters = {};
            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.plannedencounters;
               
                const l = p.plannedencounters;
                if (l) {
                    for (let e in l){
                        const x = l[e];
                        if (!x.displayName) {
                            x.displayName = x.name;
                        }
                        if (!x.deleted) {
                            addEntryToSets(vals, noBrowsePlannedEncounters, demoteEncounters, canbrowse, x, null, "Encounters", pinfo);
                        } else {
                            delete vals[x.name.toLowerCase()];
                        }
                    }            
                }
            }
            this.allPlannedEncounters = vals;
            this.noBrowsePlannedEncounters=noBrowsePlannedEncounters;
            this.demoteEncounters=demoteEncounters;
        }
        return this.allPlannedEncounters;
    }

    getPlannedEncounters() {
        let all = this.buildPlannedEncounters();
        let ret=[];

        for (let i in all){
            ret.push(all[i]);
        }

        ret.sort(sortDisplayName);
        return ret;
    }

    getPlannedEncounterInfo(name) {
        if (!name) {
            return null;
        }
        name=name.toLowerCase();
        this.buildPlannedEncounters();
        return this.noBrowsePlannedEncounters[name] || this.allPlannedEncounters[name] || this.demoteEncounters[name];
    }

    getPlayers() {
        if (!this.savedPlayerList) {
            let ret=[];

            for (let i in this.editedContent.players){
                const p = this.editedContent.players[i];
                if (!p.displayName) {
                    p.displayName = p.name;
                }
                ret.push(p);
            }

            ret.sort(sortDisplayName);
            this.savedPlayerList= ret;
        }
        return this.savedPlayerList;
    }

    getPlayerInfo(name) {
        if (!name || !this.editedContent.players)
            return null;
        return this.editedContent.players[name.toLowerCase()];
    }

    getFeedback() {
        let ret=[];

        for (let i in this.editedContent.feedback){
            const p = this.editedContent.feedback[i];
            ret.push(p);
        }

        ret.sort(function (a,b) {return (b.time||0) - (a.time||0)});
        return ret;
    }

    getFeedbackInfo(name) {
        if (!name || !this.editedContent.feedback)
            return null;
        return this.editedContent.feedback[name];
    }

    getAllMonsters() {
        if (!this.allMonsters){
            const allMonsters = {};
            const noBrowseMonsters = {};
            const demoteMonsters = {};

            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.monsters;

                if (p.monsters) {
                    for (let m in p.monsters){
                        const mon = p.monsters[m];
                        addEntryToSets(allMonsters,noBrowseMonsters,demoteMonsters,canbrowse, mon, null, "Monsters", pinfo);
                        if (!mon.displayName) {
                            mon.displayName=mon.name;
                        }
                        if (mon.npc) {
                            switch (mon.level||0) {
                                case 0:
                                    mon.cr=0;
                                    mon.crsort=0;
                                    break;
                                case 1:
                                    mon.cr="1/4";
                                    mon.crsort=0.25;
                                    break;
                                case 2:
                                case 3:
                                    mon.cr = "1/2";
                                    mon.crsort=0.5;
                                    break;
                                default:
                                    mon.cr = mon.crsort = Math.trunc((mon.level-1)/2);
                                    break;
                            }
                        } else {
                            if (typeof mon.cr == 'object'){
                                mon.cr = mon.cr.cr;
                            }
                            if (!mon.crsort) {
                                let crsort;
                                switch (mon.cr) {
                                    case "1/8":
                                        crsort=0.125;
                                        break;
                                    case "1/4":
                                        crsort=0.25;
                                        break;
                                    case "1/2":
                                        crsort=0.5;
                                        break;
                                    default:
                                        crsort = Number(mon.cr);
                                        if (!(crsort >= 0 || crsort <=30)) {
                                            console.log("mon.cr", mon, mon.cr);
                                            crsort = 100;
                                        }
                                        break;
                                }
                                mon.crsort=crsort;
                            }
                        }
                        if (!mon.cr) {
                            mon.cr = "Unknown";
                        }
                        delete mon.simplecr;
                    }
                }
            }
            const npcs = this.editedContent.npcs;
            if (npcs) {
                for (let m in npcs){
                    const mon = npcs[m];
                    const lname = mon.name.toLowerCase();
                    const am = allMonsters[lname];
                    if (am || !noBrowseMonsters[lname]) {
                        if (am && !am.unique && !am.npc) {
                            console.log("messed up", am);
                            campaign.deleteCampaignContent("npcs", lname);
                        } else {
                            allMonsters[lname]=mon;
                        }
                    } else {
                        noBrowseMonsters[lname]=mon;
                    }
                }
            }

            this.allMonsters = allMonsters;
            this.noBrowseMonsters = noBrowseMonsters;
            this.demoteMonsters = demoteMonsters;
        }
        return this.allMonsters;
    }

    getMonsterListByName() {
        if (!this.monsterNamesList) {
            let i;
            const allMonsters = this.getAllMonsters();
        
            let monsterNamesList = [];
            for (i in allMonsters) {
                monsterNamesList.push(allMonsters[i]);
            }

            monsterNamesList.sort(function(a,b){
                if (a.crsort == b.crsort)
                    return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase());
                const ret=Math.trunc(a.crsort*10-b.crsort*10);
                return ret;
            });
            this.monsterNamesList = monsterNamesList;
        }
        return this.monsterNamesList;
    }
    
    getMonster(name) {
        if (!name) {
            return null;
        }
        const allMonsters = this.getAllMonsters();
    
        name = name.toLowerCase();
        return this.noBrowseMonsters[name] || allMonsters[name] || this.demoteMonsters[name];
    }

    getNPCs() {
        if (!this.npcList){
            const npcs = this.editedContent.npcs;
            let npcList = [];
            for (let m in npcs){
                npcList.push(npcs[m]);
            }
            npcList.sort(sortDisplayName)
            this.npcList = npcList;
        }
        return this.npcList;
    }

    getAllRaces() {
        if (!this.races){
            const races = {};
            const noBrowseRaces = {};
            const demoteRaces = {};

            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p=pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.races;

                if (p.races) {
                    for (let r in p.races) {
                        const ar = p.races[r];
                        addEntryToSets(races, noBrowseRaces, demoteRaces, canbrowse, ar, null, "Races", pinfo);
                        if (!ar.displayName) {
                            ar.displayName=ar.name;
                        }
                    }
                }
            }
            this.races = races;
            this.noBrowseRaces = noBrowseRaces;
            this.demoteRaces = demoteRaces;
        }
        return this.races;
    }

    getRaces() {
        const races =this.getAllRaces();
        const raceList = [];

        for (let i in races) {
            raceList.push(races[i]);
        }
        raceList.sort(sortDisplayName);
        return raceList;
    }

    getRaceInfo(race){
        if (!race) {
            return null;
        }
        race = race.toLowerCase();
        this.getAllRaces();
        return this.noBrowseRaces[race] || this.races[race] || this.demoteRaces[race];
    }

    getAllFeats() {
        if (!this.allFeats){
            const allFeats = {};
            const noBrowseFeats = {};
            const demoteFeats = {};

            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.feats;

                if (p.feats) {
                    for (let i in p.feats) {
                        const it=p.feats[i];
                        if (!it.displayName) {
                            it.displayName=it.name;
                        }
                        addEntryToSets(allFeats, noBrowseFeats, demoteFeats, canbrowse, it, null, "Feats", pinfo);
                    }
                }
            }
            this.allFeats = allFeats;
            this.noBrowseFeats = noBrowseFeats;
            this.demoteFeats = demoteFeats;
        }
        return this.allFeats;
    }

    getSortedFeatsList() {
        if (!this.sortedFeats) {
            const feats = this.getAllFeats();
            const itl = [];

            for (let i in feats) {
                itl.push(feats[i]);
            }
            itl.sort(sortDisplayName)
            this.sortedFeats = itl;
        }
        return this.sortedFeats;
    }

    getFeatInfo(name) {
        if (!name) {
            return null;
        }

        name = name.toLowerCase();
        this.getAllFeats();
        return this.noBrowseFeats[name] || this.allFeats[name] || this.demoteFeats[name];
    }

    getAllSkills() {
        const extensions = this.getAllExtensions();
        let skills = [];

        for (let i in extensions) {
            const e = extensions[i];
            if (e.skills) {
                for (let s of e.skills) {
                    if (!skills.includes(s.name)) {
                        skills.push(s.name);
                    }
                }
            }
        }
        if (skills.length) {
            skills = skills.concat(skillsList)
            skills.sort(function (a,b){return a.toLowerCase().localeCompare(b.toLowerCase())});
            return skills;
        }
        return skillsList;
    }

    getAllSkillsWithAbilities() {
        const extensions = this.getAllExtensions();
        let skills = [];

        for (let i in extensions) {
            const e = extensions[i];
            if (e.skills) {
                for (let s of e.skills) {
                    if (!skills.find(function (a) {return s.name==a.skill})) {
                        skills.push({mod:s.ability, skill:s.name});
                    }
                }
            }
        }
        if (skills.length) {
            skills.sort(function (a,b){return (a.skill||"").toLowerCase().localeCompare((b.skill||"").toLowerCase())});

            skills = skillVals.concat(skills)
            return skills;
        }
        return skillVals;
    }

    getExtensionSkillDescriptions() {
        const extensions = this.getAllExtensions();
        let skills = {};

        for (let i in extensions) {
            const e = extensions[i];
            if (e.skills) {
                for (let s of e.skills) {
                    if (s.description) {
                        skills[s.name]=s.description;
                    }
                }
            }
        }
        return skills;
    }

    getAllLanguages() {
        const extensions = this.getAllExtensions();
        let languages = languagesList.concat([]);
        const allLang = [];
        const excludeLanguages={};

        for (let i in extensions) {
            const e = extensions[i];
            if (e.languages) {
                const langs = e.languages.split(",").map(function (a){return a.trim()});
                for (let l of langs) {
                    if (!languages.includes(l)){
                        languages.push(l);
                    }
                }
            }
            if (e.excludeLanguages) {
                e.excludeLanguages.map(function (l) {
                    excludeLanguages[l.toLowerCase()]=1;
                });
            }
        }
        for (let l of languages) {
            if (!excludeLanguages[l.toLowerCase()]) {
                allLang.push(l);
            }
        }

        return allLang;
    }

    getSortedExtensionsList() {
        const extensions = this.getAllExtensions();
        const itl = [];

        for (let i in extensions) {
            itl.push(extensions[i]);
        }
        itl.sort(sortDisplayName);
        return itl;
    }

    getAllExtensions() {
        if (!this.allExtensions){
            const allExtensions = {};
            const noBrowseExtensions = {};
            const demoteExtensions = {};

            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.extensions;

                if (p.extensions) {
                    for (let i in p.extensions) {
                        const it=p.extensions[i];
                        addEntryToSets(allExtensions, noBrowseExtensions, demoteExtensions, canbrowse, it, null, "Extensions", pinfo);
                    }
                }
            }
            this.allExtensions = allExtensions;
            this.noBrowseExtensions = noBrowseExtensions;
            this.demoteExtensions = demoteExtensions;
        }
        return this.allExtensions;
    }

    getExtensionInfo(name) {
        if (!name) {
            return null;
        }
        this.getAllExtensions();

        return this.noBrowseExtensions[name] || this.allExtensions[name] || this.demoteExtensions[name];
    }

    getAllBackgrounds() {
        if (!this.allBackgrounds){
            const allBackgrounds = {};
            const noBrowseBackgrounds = {};
            const demoteBackgrounds = {};

            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.backgrounds;

                if (p.backgrounds) {
                    for (let i in p.backgrounds) {
                        const it=p.backgrounds[i];
                        if (!it.displayName) {
                            it.displayName=it.name;
                        }
                        addEntryToSets(allBackgrounds, noBrowseBackgrounds, demoteBackgrounds, canbrowse, it, null, "Backgrounds", pinfo);
                    }
                }
            }
            this.allBackgrounds = allBackgrounds;
            this.noBrowseBackgrounds = noBrowseBackgrounds;
            this.demoteBackgrounds = demoteBackgrounds;
        }
        return this.allBackgrounds;
    }

    getSortedBackgroundsList() {
        if (!this.sortedBackgrounds) {
            const backgrounds = this.getAllBackgrounds();
            const itl = [];

            for (let i in backgrounds) {
                itl.push(backgrounds[i]);
            }
            itl.sort(sortDisplayName)
            this.sortedBackgrounds = itl;
        }
        return this.sortedBackgrounds;
    }

    getBackgroundInfo(name) {
        if (!name) {
            return null;
        }
        name = name.toLowerCase();
        this.getAllBackgrounds()
        return this.noBrowseBackgrounds[name] || this.allBackgrounds[name] || this.demoteBackgrounds[name];
    }

    getAllItems() {
        if (!this.allItems){
            const allItems = {};
            const noBrowseItems = {};
            const demoteItems = {};
            const artisantools = {};
            const tools = {"Vehicles (land)":true,"Vehicles (water)":true};
            const instruments = {};
            const gameset={};
            const martial={};
            const simple={};
            const allWeaponProficiencies = {};
            const itemTypes = Object.assign({},Parser.BASE_ITEM_TYPES);
            const itemTypeMap = Object.assign({},Parser.ITEM_TYPE_JSON_TO_ABV);
            const coins = Object.assign({}, baseCoins);
            const otherWeapon = {};
            const weaponCategories = [];

            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.items;

                if (p.items) {
                    for (let i in p.items) {
                        const it=p.items[i];
                        if (!it.displayName) {
                            it.displayName=it.name;
                        }
                        addEntryToSets(allItems, noBrowseItems, demoteItems, canbrowse, it, null, "Items", pinfo);
                        if (it.coinType&&(it.type=="coin")&&!coins[it.coinType]) {
                            const c = Object.assign({}, it);
                            delete c.quantity;
                            coins[it.coinType] = c;
                        }
                        if (canbrowse) {
                            const notRare=(!it.rarity || it.rarity=="None");
                            switch (it.type) {
                                case "AT":
                                    if (notRare) {artisantools[it.displayName]=true};
                                    break;
                                case "INS":
                                    if (notRare) {instruments[it.displayName]=true};
                                    break;
                                case "GS":
                                    if (notRare) {gameset[it.displayName]=true};
                                    break;
                                case "M":
                                case "R":
                                    const weaponProficiency = getWeaponProficiency(it);
                                    if (weaponProficiency) {
                                        const weaponCategory = it.weaponCategory||"Other";
                                        const lcwp = weaponProficiency.toLowerCase();
                                        if (weaponCategory=="Martial") {
                                            martial[lcwp]=true;
                                        } else if (weaponCategory=="Simple") {
                                            simple[lcwp]=true;
                                        } else {
                                            const lcWeaponCategory = weaponCategory.toLowerCase();
                                            if (!otherWeapon[lcWeaponCategory]) {
                                                otherWeapon[lcWeaponCategory] = {};
                                                weaponCategories.push(weaponCategory);
                                            }
                                            otherWeapon[lcWeaponCategory][lcwp]=true;
                                        }
                                        allWeaponProficiencies[lcwp]=true;
                                    }
                                    break;
                                case "T":
                                    if (notRare) {tools[it.displayName]=true};
                                    break;
                            }
                            if (it.nametype) {
                                itemTypeMap[it.nametype]=it.nametype;
                                itemTypes[it.nametype]=1;
                            } else if (it.type) {
                                itemTypes[Parser.ITEM_TYPE_JSON_TO_ABV[it.type]]=1;
                            }
                        }
                    }
                }
            }
            this.artisantools = Object.keys(artisantools).sort();
            this.tools = Object.keys(tools).sort();
            this.instruments = Object.keys(instruments).sort();
            this.gameset = Object.keys(gameset).sort();
            this.martial = Object.keys(martial).sort();
            this.simple = Object.keys(simple).sort();
            this.allWeaponProficiencies = Object.keys(allWeaponProficiencies).sort();
            this.allWeaponProficiencies = Object.keys(allWeaponProficiencies).sort();
            this.allItemTypes = Object.keys(itemTypes).sort();
            this.allItemTypeMap = itemTypeMap;
            this.knownCoins = coins;
            
            this.weaponProficiencies = ["*simple"].concat(this.simple).concat(["*martial"]).concat(this.martial);
            weaponCategories.sort(function(a,b){return (a||"").toLowerCase().localeCompare((b||"").toLowerCase())});
            this.weaponCategories = ["Simple", "Martial"].concat(weaponCategories);
            this.toolProficiencies = ["*Trade Tools"].concat(this.tools).concat(["*Artisan's Tools"]).concat(this.artisantools).concat(["*Gaming Set"]).concat(this.gameset).concat(["*Musical Instrument"]).concat(this.instruments);
            this.chooseProficienciesExpand = {
                "Trade Tools":this.tools,
                "Artisan's Tools":this.artisantools,
                "Gaming Set":this.gameset,
                "Musical Instrument":this.instruments,
                "martial":this.martial,
                "simple":this.simple,
            }
            for (let wc of weaponCategories) {
                const list = Object.keys(otherWeapon[wc.toLowerCase()]).sort();
                this.weaponProficiencies.push("*"+wc.toLowerCase());

                this.weaponProficiencies = this.weaponProficiencies.concat(list);
                this.chooseProficienciesExpand[wc.toLowerCase()] = list;
            }
            this.toolSingleProficiencies = this.tools.concat(this.artisantools).concat(this.gameset).concat(this.instruments).sort();
            
            this.allItems = allItems;
            this.noBrowseItems = noBrowseItems;
            this.demoteItems = demoteItems;
        }
        return this.allItems;
    }

    getItemExtensions() {
        this.getAllItems();
        return {
            toolProficiencies:this.toolProficiencies,
            chooseProficienciesExpand:this.chooseProficienciesExpand,
            toolSingleProficiencies:this.toolSingleProficiencies,
            tools:this.tools,
            instruments:this.instruments, 
            gameset:this.gameset, 
            allWeaponProficiencies:this.allWeaponProficiencies,
            weaponCategories:this.weaponCategories,
            artisantools:this.artisantools,
            weaponProficiencies:this.weaponProficiencies,
            allItemTypes:this.allItemTypes,
            allItemTypeMap:this.allItemTypeMap,
            knownCoins:this.knownCoins
        };
    }

    getSortedItemsList() {
        if (!this.sortedItems) {
            const items = this.getAllItems();
            const itl = [];

            for (let i in items) {
                itl.push(items[i]);
            }
            itl.sort(sortDisplayName)
            this.sortedItems = itl;
        }
        return this.sortedItems;
    }

    getItem(name) {
        if (!name) {
            return null;
        }

        name = name.toLowerCase();
        this.getAllItems();
        return this.noBrowseItems[name] || this.allItems[name] || this.demoteItems[name];
    }

    getAllCustom(type) {
        if (!this.customTypes) {
            this.customTypes = {};

            const packageList = this.getCurrentPackageList();
            const customTypes = this.customTypes;

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse?.customTypes;

                if (p.customTypes) {
                    const cts = p.customTypes;
                    for (let i in cts) {
                        const ct = cts[i];

                        if (!customTypes[ct.type]) {
                            customTypes[ct.type] = { all:{}, noBrowse:{}, demote:{}};
                        }
                        if (!ct.id) {
                            ct.id = ct.displayName;
                        }
                        if (!ct.features) {
                            ct.features=[{entries:ct.entries}];
                            delete ct.entries;
                        }
                        addEntryToSets(customTypes[ct.type].all, customTypes[ct.type].noBrowse, customTypes[ct.type].demote, canbrowse, ct, ct.id.toLowerCase(), ct.type, pinfo);
                    }
                }
            }
        }

        if (type) {
            return (this.customTypes[type] && this.customTypes[type].all) || {};
        } else {
            return this.customTypes;
        }
    }

    getAllCustomList() {
        const list = [];
        const customTypes = this.getAllCustom();
        for (let ct in customTypes) {
            for (let t in customTypes[ct].all) {
                list.push(customTypes[ct].all[t]);
            }
        }
        return list;
    }

    getSortedCustomList(type) {
        switch (type) {
            case "Feats":
                return this.getSortedFeatsList();
        }
        if (!this.customTypes || !this.customTypes[type] || !this.customTypes[type].sorted) {
            const l = this.getAllCustom(type);
            if (this.customTypes[type]) {
                const itl = [];

                for (let i in l) {
                    itl.push(l[i]);
                }
                itl.sort(sortDisplayName)
                if (itl.length) {
                    this.customTypes[type].sorted = itl;
                }
            }
        }
        return (this.customTypes[type] && this.customTypes[type].sorted)||[];
    }

    getCustom(type, name) {
        if (!name) {
            return null;
        }

        switch (type) {
            case "Feats":
                return this.getFeatInfo(name);
        }

        name=name.toLowerCase();
        this.getAllCustom();
        const ct = this.customTypes[type]||{};

        return (ct.noBrowse||{})[name] || (ct.all||{})[name] || (ct.demote||{})[name];
    }

    getCustomTablesList(includeExtra) {
        const l = this.getAllCustom();
        const itl = includeExtra?["Feats"]:[];

        for (let i in l) {
            itl.push(i);
        }
        itl.sort();
        return itl;
    }

    getAllSpells() {
        if (!this.allSpells){
            const allSpells = {};
            const noBrowseSpells = {};
            const demoteSpells = {};

            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.spells;

                if (p.spells) {
                    for (let m in p.spells){
                        const sp=p.spells[m];
                        if (!sp.displayName) {
                            sp.displayName=sp.name;
                        }
                        addEntryToSets(allSpells, noBrowseSpells, demoteSpells, canbrowse, sp, null, "Spells", pinfo);
                    }
                }
            }
            
            this.allSpells = allSpells;
            this.noBrowseSpells = noBrowseSpells;
            this.demoteSpells = demoteSpells;
        }
        return this.allSpells;
    }

    getSpellListByName() {
        if (!this.spellNamesList) {
            let i;
            const allSpells = this.getAllSpells();
        
            let spellNamesList = [];
            for (i in allSpells) {
                spellNamesList.push(allSpells[i]);
            }
        
            spellNamesList.sort(function(a,b){
                if (a.level != b.level) 
                    return a.level-b.level; 

                return sortDisplayName(a,b);
            });
            this.spellNamesList = spellNamesList;
        }
        return this.spellNamesList;
    }
    
    getSpell(name) {
        if (!name){
            return null;
        }
        const allSpells = this.getAllSpells();
        name = name.toLowerCase();
    
        return this.noBrowseSpells[name] || allSpells[name] || this.demoteSpells[name];
    }

    getClassesListByName() {
        let i;
        const allClasses = this.getClasses();
    
        let classNamesList = [];
        for (i in allClasses) {
            classNamesList.push(allClasses[i]);
        }
    
        classNamesList.sort(sortDisplayName);
        return classNamesList;
    }
    
    getSubclasses(cls) {
        if (!cls) {
            return null;
        }
        let i;
        this.getClasses();
        const subs = this.subclasses[cls.toLowerCase()];
    
        let subclasses = [];
        for (i in subs) {
            subclasses.push(subs[i]);
        }
    
        subclasses.sort(sortDisplayName);
        return subclasses;
    }
    
    getClasses() {
        if (!this.classes){
            const classes = {};
            const noBrowseClasses = {}
            const demoteClasses = {}
            const subclasses = {};
            const noBrowseSubclasses = {};
            const demoteSubclasses = {};
            const subclassesAll = {};
            const noBrowseSubclassesAll = {};
            const demoteSubclassesAll = {};
            const packageList = this.getCurrentPackageList();

            for (let pi in packageList) {
                const pinfo = packageList[pi];
                const p = pinfo.pkg;
                const canbrowse = !pinfo.skipBrowse || !pinfo.skipBrowse.classes;

                if (p.classes) {
                    for (let c in p.classes){
                        const cls = p.classes[c];
                        const clsname = (cls.className||"").toLowerCase();
                        if (!cls.displayName) {
                            cls.displayName = cls.subclassName||cls.className;
                        }

                        if (!cls.subclassName) {
                            addEntryToSets(classes, noBrowseClasses, demoteClasses, canbrowse, cls, clsname, "Classes", pinfo);
                        } else {
                            const subname = cls.subclassName.toLowerCase();
                            if (!subclasses[clsname]) {
                                subclasses[clsname]={};
                                noBrowseSubclasses[clsname]={};
                                demoteSubclasses[clsname]={};
                            }
                            addEntryToSets(subclasses[clsname],noBrowseSubclasses[clsname],demoteSubclasses[clsname], canbrowse, cls, subname, "Subclasses", pinfo);
                            addEntryToSets(subclassesAll,noBrowseSubclassesAll, demoteSubclassesAll, canbrowse, cls, subname, "Subclasses", pinfo);
                        }
                    }
                }
            }

            this.classes = classes;
            this.noBrowseClasses = noBrowseClasses;
            this.demoteClasses = demoteClasses;

            this.subclasses = subclasses;
            this.noBrowseSubclasses=noBrowseSubclasses;
            this.demoteSubclasses = demoteSubclasses;

            this.subclassesAll = subclassesAll;
            this.noBrowseSubclassesAll = noBrowseSubclassesAll;
            this.demoteSubclassesAll = demoteSubclassesAll;
        }
        return this.classes;
    }

    getClassInfo(cls) {
        if (!cls) {
            return null;
        }
        const classes = this.getClasses();
        cls=cls.toLowerCase();
        return this.noBrowseClasses[cls] || classes[cls] || this.demoteClasses[cls];
    }

    getSubclassInfo(subclass) {
        if (!subclass) {
            return null;
        }
        this.getClasses();
        subclass=subclass.toLowerCase();
        return this.noBrowseSubclassesAll[subclass] || this.subclassesAll[subclass] || this.demoteSubclassesAll[subclass];
    }

    getSubclassTree() {
        this.getClasses();
        return this.subclasses;
    }
}

function addEntryToSet(set, e, name, type, pinfo, aset) {
    const lname = (name || e.name).toLowerCase();
    const c = set[lname];

    if (pinfo.entryTypes || pinfo.entryIds) {
        if (type != "Art" &&
            !(pinfo.entryTypes && pinfo.entryTypes[type]) && 
            !(pinfo.entryIds && pinfo.entryIds[e.name.toLowerCase()])
        ) {
            //console.log("not a match for partial", type, e.displayName);
            return;
        }
    }

    if (c && c.className && e.className && (c.className != e.className)) {
        console.log("mismatched classes", c.className, e)
    }

    if ((!c && ((!aset || !aset[lname])||!pinfo.alwaysDemote)) || (!pinfo.alwaysDemote && (e.edited || (!c.timestamp && !e.timestamp) || ((c.timestamp||0) < (e.timestamp||0))))) {
        set[lname] = e;
    }
}

function addEntryToSets(browseSet, noBrowseSet, demoteSet, canbrowse, e, name, type, pinfo) {
    const lname = (name || e.name).toLowerCase();
    const alwaysDemote = pinfo.alwaysDemote;
    const set=canbrowse?browseSet:alwaysDemote?demoteSet:noBrowseSet;
    const aset = canbrowse?null:browseSet;
    const c = set[lname];

    if (pinfo.entryTypes || pinfo.entryIds) {
        if (type != "Art" &&
            !(pinfo.entryTypes && pinfo.entryTypes[type]) && 
            !(pinfo.entryIds && (pinfo.entryIds[e.name]||pinfo.entryIds[e.name.toLowerCase()]))
        ) {
            //console.log("not a match for partial", type, e.displayName, e.name);
            return;
        }
    }

    if (c && c.className && e.className && (c.className != e.className)) {
        console.log("mismatched classes", c.className, e)
    }

    if ((!c && ((!aset || !aset[lname])||!alwaysDemote)) || (!alwaysDemote && (e.edited || (!c.timestamp && !e.timestamp) || ((c.timestamp||0) < (e.timestamp||0))))) {
        set[lname] = e;

        if (canbrowse && noBrowseSet[lname]) {
            delete noBrowseSet[lname];
            //console.log("found dup entry", browseSet[lname], e);
        }
    }
}

const gpValueMap = {
    cp:100,
    sp:10,
    gp:1,
    ep:2,
    pp:0.1
}
function upgradeItem(it) {
    if (!it) {
        return;
    }

    if (it.coin) {
        it.type = "coin";
        delete it.coin;
        if (!it.weight) {
            it.weight=0.02;
        }
    }

    if ((it.type == "coin") && !it.gpValue && (gpValueMap[it.coinType]))  {
        it.countPerGP = gpValueMap[it.coinType];
    }

    if (it.displayName === undefined) {
        it.displayName=it.name;
    }

    if (it.feature) {
        return;
    }

    const feature = {auto:false};
    if (it.additionalEntries) {
        it.entries = (it.entries||[]).concat(it.additionalEntries);
        delete it.additionalEntries;
    }
    it.entries = it.entry?[it.entry]:it.entries || null;
    delete it.entry;

    if (it.ability) {
        const ability={};
        for (let i in it.ability) {
            const a = it.ability[i];
            if (a.modifier) {
                ability[i]=a.modifier;
            }
            if (a.minValue) {
                ability["min"+i]=a.minValue;
            }
        }
        delete it.ability;
        feature.ability = ability;
    }

    if (it.languages) {
        feature.languages = it.languages;
        delete it.languages;
    }

    if (it.senses) {
        feature.senses = it.senses;
        delete it.senses;
    }

    if (it.acBonus) {
        feature.acBonus = it.acBonus;
        delete it.acBonus;
    }

    if (it.noArmorBonus) {
        feature.acBonus = it.noArmorBonus
        feature.acBonusType="noarmor";
        delete it.noArmorBonus;
    }

    if (it.noArmorShieldBonus) {
        feature.acBonus = it.noArmorShieldBonus;
        feature.acBonusType="noarmornoshield";
        delete it.noArmorShieldBonus;
    }

    if (it.proficiencyBonus) {
        feature.proficiencyBonus = it.proficiencyBonus;
        delete it.proficiencyBonus;
    }

    if (it.spellDCBonus) {
        feature.spellDCBonus = it.spellDCBonus;
        delete it.spellDCBonus;
    }

    if (it.spellAttackBonus) {
        feature.spellAttackBonus = it.spellAttackBonus;
        delete it.spellAttackBonus;
    }

    if (it.savingThrowBonus) {
        feature.savingThrowBonus = it.savingThrowBonus;
        delete it.savingThrowBonus;
    }

    if (it.perceptionBonus) {
        feature.perceptionBonus = it.perceptionBonus;
        delete it.perceptionBonus;
    }

    if (it.initiativeBonus) {
        feature.initiativeBonus = it.initiativeBonus;
        delete it.initiativeBonus;
    }

    if (it.usage) {
        feature.usage=it.usage;
        delete it.usage;
        if (it.uses) {
            feature.uses = it.uses;
        }
    } 

    if (it.attackBonus) {
        feature.damageBonus=it.attackBonus;
        feature.attackBonus=it.attackBonus;
    }
    it.feature = feature;

    //console.log("new item", it);
}


function storagePathFromDownloadURL(url) {
    let p = url.indexOf("/users");
    if (p < 0) {
        return "";
    }

    url = url.substr(p+1);

    p = url.indexOf("?");

    url = url.substr(0, p);
    return decodeURIComponent(url);
}

function areSameDeepDebug(a,b) {
    if ((a == b) || (Number.isNaN(a) && Number.isNaN(b))) {
        return true;
    }

    if ((typeof a != 'object') || (typeof b != 'object')) {
        console.log("not both objects", a,b);
        return false;
    }

    const aisArray = Array.isArray(a);
    const bisArray = Array.isArray(b);
    if (aisArray != bisArray) {
        console.log("array mismatch", a,b);
        return false;
    }
    if (aisArray) {
        if (a.length != b.length){
            console.log("array length mismatch", a,b);
            return false;
        }
    } else {
        if (!a || !b) {
            return false;
        }
        const ak = Object.keys(a);
        const bk = Object.keys(b);
        if (ak.length != bk.length) {
            console.log("object key mismatch", a,b);
            return false;
        }
    }

    for (let i in a) {
        if (typeof a[i] == 'object') {
            if (!areSameDeepDebug(a[i], b[i])){
                console.log("prop", i, a[i], b[i])
                return false;
            }
        } else if (!areSameDeepDebug(a[i],b[i])) {
            console.log("prop", i, a[i], b[i])
            return false;
        }
    }
    return true;
}

function areSameDeep(a,b) {
    if ((a == b) || (Number.isNaN(a) && Number.isNaN(b))) {
        return true;
    }

    if ((typeof a != 'object') || (typeof b != 'object')) {
        return false;
    }

    const aisArray = Array.isArray(a);
    const bisArray = Array.isArray(b);
    if (aisArray != bisArray) {
        return false;
    }
    if (aisArray) {
        if (a.length != b.length){
            return false;
        }
    } else {
        if (!a || !b) {
            return false;
        }
        const ak = Object.keys(a);
        const bk = Object.keys(b);
        if (ak.length != bk.length) {
            return false;
        }
    }

    for (let i in a) {
        if (!areSameDeep(a[i], b[i])){
            return false;
        }
    }
    return true;
}

function areSameDeepIgnore(a,b, ignore) {
    if ((a == b) || (Number.isNaN(a) && Number.isNaN(b))) {
        return true;
    }

    if ((typeof a != 'object') || (typeof b != 'object')) {
        return false;
    }

    const aisArray = Array.isArray(a);
    const bisArray = Array.isArray(b);
    if (aisArray != bisArray) {
        return false;
    }
    if (aisArray) {
        if (a.length != b.length){
            return false;
        }
    } else {
        if (!a || !b) {
            return false;
        }
    }

    for (let i in a) {
        if (!(ignore.includes(i)) && !areSameDeepIgnore(a[i], b[i],ignore)){
            //console.log("no match", i)
            return false;
        }
    }
    for (let i in b) {
        if (!(ignore.includes(i)) && !areSameDeepIgnore(a[i], b[i],ignore)){
            //console.log("no match", i)
            return false;
        }
    }
    return true;
}

function areSameDeepInst(a,b) {
    if ((a == b) || (Number.isNaN(a) && Number.isNaN(b))) {
        return true;
    }

    if ((typeof a != 'object') || (typeof b != 'object')) {
        //console.log("obj mismatch", a, b);
        return false;
    }

    const aisArray = Array.isArray(a);
    const bisArray = Array.isArray(b);
    if (aisArray != bisArray) {
        //console.log("array mismatch", a,b);
        return false;
    }
    if (aisArray) {
        if (a.length != b.length){
            //console.log("array length mismatch", a,b)
            return false;
        }
    } else {
        if (!a || !b) {
            //console.log("null mismatch", a, b);
            return false;
        }
        const ak = Object.keys(a);
        const bk = Object.keys(b);
        if (ak.length != bk.length) {
            //console.log("obj vals mismatch", a, b,ak,bk);
            return false;
        }
    }

    for (let i in a) {
        if (!areSameDeepInst(a[i], b[i])){
            console.log("diff=", i);
            return false;
        }
    }
    return true;
}

function computePurchasePackages(purchases, base) {
    const list = base||[];
    const {packageList, bonusPackages, purchasedPackages} = purchases;

    for (let i in alwaysIncludePackages) {
        addToList({id:alwaysIncludePackages[i]});
    }

    for (let i in defaultOwnedPackages) {
        addToList({id:defaultOwnedPackages[i]});
    }

    for (let i in packageList) {
        addToList({id:packageList[i]});
    }

    for (let i in bonusPackages) {
        addToList({id:i});
    }

    for (let i in purchasedPackages) {
        addToList(Object.assign({id:i},purchasedPackages[i]));
    }

    return list;

    function addToList(pinfo) {
        const pos = list.findIndex(function (a){
            return !a.entryIds && !pinfo.entryIds && !a.entryTypes && !pinfo.entryTypes && (a.id == pinfo.id);
        });

        if (pos <0) {
            list.push(pinfo);
        }
    }
}

function loadPackages(pkgs, dp) {
    const promises = [];

    for (let x in pkgs) {
        const i = pkgs[x].id;
        if (!loadedPackages[i] && !dp[i]) {
            promises.push(loadPackage(i,true));
        }
    }

    // this will make sure that the special package for feature lookup is available
    if (!loadedPackages[featureLookupPackage]) {
        promises.push(loadPackage(featureLookupPackage,true));
    }
    return Promise.all(promises);
}

async function loadPackage(pkg,delayUpdate) {
    const url = getDirectDownloadUrl("packages/"+pkg);
    let pkgCache;
    try {
        pkgCache = await caches.open("packages");
    } catch (err) {
        try {
            caches.delete("packages");
        } catch (er) {
            console.log("could not delete packages", er);
        }
    }
    let noFetch;


    if (loadedPackages[pkg]) {
        noFetch = true;
    } else if (pkgCache) {
        try {
            const cresponse = await pkgCache.match(url);
            if (cresponse) {
                //console.log("loaded from cache",pkg)
                loadedPackages[pkg] = await cresponse.json();
                noFetch=true;
            }
        } catch (err) {
            console.log("error checking cache", err);
        }
    }

    if (noFetch && pkgCache) {
        try {
            updatePackage(pkgCache, pkg,delayUpdate)
        } catch(err) {
            console.log("error updating package", err);
        }
    } else {
        await getPackageInfo(pkgCache, pkg, false);
    }
}

async function getPackageInfo(pkgCache, pkg) {
    const url = getDirectDownloadUrl("packages/"+pkg);
    let response;
    let retryCount=0;

    do {
        try {
            response = await fetch(url);
        } catch (err) {
            console.log("error fetching", err);
            response.status="unknown";
        }
        if (response.ok) {
            const clone = await response.clone();
            const res = await response.json();
            loadedPackages[pkg] = res;
            if (pkgCache) {
                try {
                    pkgCache.put(url, clone);
                } catch (err){
                    console.log("ignore cache error");
                }
            }
            return;
        } else if (response.status == 404) {
            // ignore missing packages
            if (pkgCache) {
                pkgCache.delete(url);
            }
            return;
        }
        console.log("error loading package", pkg,response.status);
        //window.alert("Error loading package: "+retryCount+"-"+pkg+" - "+respons.status);
        await sleep(Math.random()*retryCount*1000);
        retryCount++;
    } while (retryCount < 10);
    throw(new Error("Could not download package:"+pkg+" error:"+response.status));
}

async function updatePackage(pkgCache, pkg,delayUpdate) {
    const url = getDirectDownloadUrl("packages/"+pkg);
    let response;
    let retryCount=0;

    if (delayUpdate) {
        //console.log("delay package update")
        await sleep(Math.random()*2000+5000);
    }
    do {
        const cresponse = await pkgCache.match(url);
        if (cresponse) {
            const lm = cresponse.headers.get("last-modified");
            const tresponse = await fetch(getInforUrl("packages/"+pkg));
            if (tresponse.ok){
                const data = await tresponse.json();
                const dlm = new Date(lm);
                const dc = new Date(data.timeCreated);
                if (Math.trunc(dlm.getTime()/1000)==Math.trunc(dc.getTime()/1000)) {
                    // times match means already have the correct value cached
                    //console.log("no change to package", pkg);
                    return;
                }
                //console.log("looks like packaged changed", pkg);
            }
        }

        response = await fetch(url);
        if (response.ok) {
            const clone = await response.clone();
            const res = await response.json();
            if (!areSameDeep(loadedPackages[pkg], res)){
                //console.log("package was updated", pkg);
                loadedPackages[pkg] = res;

                campaign.resetData();
                pkgCache.put(url, clone);
            }
            return;
        } else if (response.status == 404) {
            // ignore missing packages
            pkgCache.delete(url);
            return;
        } else {
            console.log("response error", response.status);
        }
        await sleep(Math.random()*retryCount*1000);
        retryCount++;
    } while (retryCount < 10);
    console.log("could not get updated package", pkg, response.status);
}

const storageUrlBase = "https://firebasestorage.googleapis.com/v0/b/shard-alley.appspot.com/o/";
function getDirectDownloadUrl(path) {
    return storageUrlBase+encodeURIComponent(path)+"?alt=media";
}

function getInforUrl(path) {
    return storageUrlBase+encodeURIComponent(path);
}

function isNativeUrl(path) {
    return (path||"").startsWith(storageUrlBase);
}

const uidNumMap = ["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];
function newUid() {
    const length = 16;
    let ret = "";
    let array; 
    if (window.crypto) {
        array = new Uint8Array(length);
        window.crypto.getRandomValues(array);
    } else {
        array = [];
        for (let i =0; i< length; i++) {
            array[i] = Math.trunc(255*Math.random());
        }
    }
    for (let i =0; i< length; i++) {
        ret = ret + uidNumMap[array[i]%36];
    }
    return ret;
}

function getVersionId() {
    return Math.trunc(1000000*Math.random());
}

function isOwned(products, product_id, entry_type, entry_id) {
    const pp = (products||{})[product_id];

    if (!pp) {
        return null;
    }
    if (pp.all) {
        return pp;
    }
    if (!entry_type) {
        return null;
    }
    const pe = (pp.entries||{})[entry_type];
    if (!pe) {
        return null;
    }
    if (pe.all) {
        return pp;
    }
    if (!entry_id) {
        return false;
    }
    return pe[entry_id]?pp:null;
}

async function httpAuthRequest(method, relurl, payload) {
    const currentUser = campaign.currentUser;
    const idToken = currentUser?(await currentUser.getIdToken(/* forceRefresh */ false)):null;

    const response = await (new Promise(function (resolve, reject) {
        let xhttp = new XMLHttpRequest();
        const url = location.origin+relurl;
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == 4) {
                if (xhttp.status == 200) {
                    resolve(xhttp.responseText);
                } else {
                    const err = new Error((xhttp.responseText||"")+" "+xhttp.status);
                    err.status = xhttp.status;
                    reject(err);
                }
            }
        }
        xhttp.open(method, url, true);
        xhttp.setRequestHeader("auth", idToken);
        xhttp.send(payload);
    }));
    return response;
}

async function httpAuthRequestWithRetry(method, relurl, payload) {
    let retryCount=0;

    do {
        try {
            const response = await httpAuthRequest(method, relurl, payload);
            return response;
        } catch(err) {
            if (([503, 502, 500].includes(err.status)) && (retryCount < 5)) {
                console.log("doing retry", retryCount);
                retryCount++;
                await sleep(Math.random()*retryCount*2000);
            } else {
                throw(err);
            }
        }
    } while (true);
}

async function httpGetWithRetry(url) {
    let retryCount=0;

    do {
        try {
            const response = await httpGet(url);
            return response;
        } catch(err) {
            if (([503, 502, 500].includes(err.status)) && (retryCount < 5)) {
                console.log("doing retry", retryCount);
                retryCount++;
                await sleep(Math.random()*retryCount*1000);
            } else {
                throw(err);
            }
        }
    } while (true);
}

function sleep(time) {
    return new Promise(function (resolve,reject){
        setTimeout(function (){
            resolve();
        }, Math.trunc(time)+1);
    });
}

async function httpGet(url) {
    const response = await (new Promise(function (resolve, reject) {
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == 4) {
                if (xhttp.status == 200) {
                    resolve(xhttp.responseText);
                } else {
                    const error = new Error("Error performing get");
                    error.status = xhttp.status;
                    error.responseText = xhttp.responseText;
                    reject(error);
                }
            }
        }
        xhttp.open("GET", url, true);
        xhttp.send();
    }));
    return response;
}

async function httpHeadRequest(url) {
    if (!url || !url.startsWith(storageUrlBase)) {
        throw(new Error("not a native storage url"));
    }
    let refUrl = decodeURIComponent(url.substr(storageUrlBase.length).split("?")[0]);
    const fileRef = ref(getStorage(firebase), refUrl);
    const meta = await getMetadata(fileRef);
    return meta;
}

function sortDisplayName(a,b){
    return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase());
}

function replaceMetawords(string, obj, eobj) {
    if (!string || (typeof string != "string")) {
        return string;
    }
    return String.prototype.replace.call(string, /{([^{}]*)}/g, function (a, b) {
        let mult=1;
        let type;
        b=b.toLowerCase();
        if (b.includes("*")) {
            type="*";
        }else if (b.includes("/")) {
            type="/";
        }
        if (type){
            const s = b.split(type);
            if (s.length==2) {
                const n = Number(s[1].trim());
                if (n&&!Number.isNaN(n)) {
                    mult=(type=="*")?n:1/n;
                    b=s[0].trim();
                }
            }
        }
        var r = (eobj && eobj[b]) || (obj && obj[b]);
        if (typeof r === 'number') {
            r=Math.trunc(r*mult);
        }
        return typeof r === 'string' || typeof r === 'number' ? r : "0";
    });
}

function addCampaignToPath(path,extraParam) {
    if (campaign.isDefaultCampaign()) {
        return path;
    }

    if (campaign.getPrefs().playerMode != "ruleset") {
        return path;
    }

    const cid = nameEncode(campaign.getCurrentCampaign());
    return path+(extraParam?"&cid=":"?cid=")+cid;
}

function getExtensionEntryCheckFn(character, checkGamesystem, gamesystemPref) {
    const preferred = {};
    const disallowed = {};
    let gamesystems;
    if (checkGamesystem) {
        if (gamesystemPref) {
            gamesystems=[gamesystemPref];
        } else {
            gamesystems = character?[character.gamesystem]:campaign.gamesystems;
        }
    }

    const extensions = character?character.extensions:extensionsFromSaved(campaign.getGameState().extensions||[]);
    for (let i of extensions) {
        const e = campaign.getExtensionInfo(i);
        if (e) {
            if (e.preferred) {
                Object.assign(preferred, e.preferred);
            }
            if (e.disallowed) {
                Object.assign(disallowed, e.disallowed);
            }
        }
    }

    if (!campaign.isDefaultCampaign()) {
        const s = campaign.getGameState();
        if (s) {
            if (s.preferred) {
                Object.assign(preferred, s.preferred);
            }
            if (s.disallowed) {
                Object.assign(disallowed, s.disallowed);
            }
        }
    }

    return function (it) {
        const p = preferred[it.name.toLowerCase()];
        const d = disallowed[it.name.toLowerCase()];
        let matchGamesystem=true;

        if (checkGamesystem && gamesystems && gamesystems.length) {
            const itgs = it.gamesystem||"5e";
            if ((itgs!="any") && !gamesystems.includes(itgs)) {
                matchGamesystem = false;
            }
        }

        if (p && !d) {
            if (!matchGamesystem) {
                return 0;
            }
            return -1;
        }
        if (d && !p) {
            return 100;
        }

        if (!matchGamesystem || (campaign.isSharedCampaign() && !campaign.isCampaignPackage(it.source) && (it.edited || !campaign.isSharedEdited(it.name)))) {
            return 1;
        }
        return 0;
    }

}

function extensionsFromSaved(extensions) {
    if (!extensions) {
        return [];
    }
    if (Array.isArray(extensions)) {
        return extensions;
    }
    const ea = Object.keys(extensions||{});
    ea.sort();
    return ea;
}

function getLastModified(it) {
    if (it.timestamp) {
        const date = new Date(it.timestamp);
        return date.toLocaleDateString()
    }
    return ""
}

const campaign = new campaignObject();
export {
    campaign,
    globalDataListener,
    newUid,
    storagePathFromDownloadURL,
    areSameDeep,
    areSameDeepInst,
    areSameDeepDebug,
    upgradeItem,
    getDirectDownloadUrl,
    isOwned,
    httpAuthRequest,
    sortDisplayName,
    enforcePermissions,
    isNativeUrl,
    httpHeadRequest,
    httpAuthRequestWithRetry,
    adventurerProductId,
    gamemasterProductId,
    gamemasterProProductId,
    areSameDeepIgnore,
    replaceMetawords,
    sleep,
    featureLookupPackage,
    addCampaignToPath,
    getExtensionEntryCheckFn,
    extensionsFromSaved,
    getLastModified
};